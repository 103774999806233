import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import ActivityInfo from "./activityInfo";
import ActivityErisiteInfo from "./activityErisiteInfo";
import ActivityChecklists from "./activityChecklists";
import RateActivity from "../activity-inbox/activities/rateActivity";
import RecordingModal from "../../components/recordingModal";
import DocumentDialogModal from "../../components/documentDialogModal";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import EhsPingList from "../ehs-pings/ehsPingList";
import EuiTile from "../../eui-components/tile";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import ActivityFeatures from "./activityFeatures";
import DialogModal from "../../components/dialog";
import PageHelper from "../../utils/page-helper";
import { saveAs } from 'file-saver';
import DownloadFilterModal from "../../components/downloadFilterModal";
import WorkplanHelper from "../../utils/workplan-helper";
import CommonHelper from "../../utils/common-helper";
import DocumentLoadingModal from "../../components/documentLoadingModal";
import Report from '../sessions/my-sessions/chooseReport';
import ActivityDocuments from './activityDocuments'
import ApiClient from "../../utils/api-client";
import EuiTooltip from "../../eui-components/tooltip";
import ErisiteUpdateActivity from "./erisiteUpdateActivity";
import DateUtils from "../../utils/date-utils";
import TeamCertificationItem from "../live-stream/live-streaming/team-certification/teamCertificationItem";
import GeneralChat from "../chatTools/dashboard";
import { setChat } from "../../store/actions/roomAction";
import SasHelper from "../../utils/sas-helper";
import jobRoles from "../asp-resources/jobRoles";
import { Logs } from "../../utils/Log";

class ActivityDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialogPreview: false,
            selectedRecording: { id: 0 },
            percentageArr: [],
            openDialogFilter: false,
            openDialogDownloadBar: false,
            allDocument: [],
            isLoading: false,
            downloadFilter: {
                emptyChecklist: true,
                images: true,
                videos: true,
                pdfs: true,
                otherDocuments: true,
                accepted: true,
                rejected: true,
                pending: true,
                recordings: true,
                openDialogDownload: false,
                exportDone: false
            },
            compressPercentage: 0,
            filteredDocuments: [],
            allDocumentTypeSizes: { total: 0, images: 0, videos: 0, pdfs: 0, otherDocuments: 0, recordings: 0 },

            activities: [],

            openDialogErisite: false,
            erisiteActivity: null,
            disable: false,
            selectedItem: 1
        }
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        const activity = this.props.activityData.activity;
        if (activity &&
            activity.subactivities &&
            activity.subactivities.map(item => item.documents && item.documents.length > 0).length > 0) {
            const allDocument = [...activity.subactivities.map(item => item.documents).reduce((a, b) => { return a.concat(b); }, []), ...(activity.recordings ?? [])];
            // user ID was parseInted
            this.setState({
                activities: activity
            })
            //  const activities = { ...activity }

            this.setState({ allDocument },
                () => {
                    PageHelper.getPropertiesOfBlobAzure(this.state.allDocument)
                        .then(response => {
                            const files = [...(response || []).filter(item => item)];
                            const allDocumentTypeSizes = PageHelper.getFilesSizeByType(files);
                            this.setState({ allDocumentTypeSizes });
                        })
                        .catch(error => {
                        });
                });
        }
    }

    openDownload = () => {
        this.setState({
            openDialogDownload: true
        }, () => {
        })
        this.props.getReport("photoReport")

    }

    componentDidUpdate(prevProps, prevState)
    {
        console.log('prevState', prevState);
        console.log('this.state', this.state);
    }

    getMenuList = () => {
        const activity = this.props.activityData.activity;
        this.props.setChat(activity.id, activity.rscScope, null)
        const menu = [{
            id: 1,
            title: "Activity Information",
            icon: <i className="icon icon-info m-1 text-lg"></i>,
            rightIcon: <>
                {this.getRevertRSCApprovalButton()}
                {this.getSyncButton()}
                <EuiTooltip title="Download Session"><i className="icon icon-download-save icon-lg icon-frame" onClick={() => { this.setState({ openDialogFilter: true }) }}></i></EuiTooltip>
                <EuiTooltip title="Export QA photo report"><i className="icon icon-document icon-lg icon-frame" style={{ marginRight: 0, marginLeft: 0 }} onClick={() => this.openDownload()}></i></EuiTooltip>
            </>,
            content: <ActivityInfo />,
            rightPanel: {
                title: "Activity Features", content: <ActivityFeatures />
            },
            trigger: "Activity Features"
        },
        {
            id: 2, title: activity.externalSystemRecordId? "SiteTracker Information" : "Erisite Information", icon: <i className="icon icon-info m-1 text-lg"></i>,
            rightIcon: this.getErisiteButton(),
            hidden: (!activity.systemrecordid && !activity.externalSystemRecordId),
            content: <ActivityErisiteInfo />
        },
        {
            id: 3, title: "EHS Pings (" + activity.ehsPings?.length + ")", icon: <i className={"icon icon-image m-1 text-lg"}></i>,
            content: <EhsPingList id="activityEhsPingsTable"
                ehsPings={this.props.activityData.activity.ehsPings}
                type={this.props.activityData.activity.type !== constants.activityListTypes.ACTIVITY ? constants.activityListTypes.WORKPLAN_ACTIVITY : constants.activityListTypes.ACTIVITY}
                height="475px"
            />
        },
        {
            id: 4, title: "Checklist Items (" + activity.subactivities?.length + ")", icon: <i className="icon icon-view-list m-1 text-lg"></i>,
            content: <ActivityChecklists />
        },
        {
            id: 5, title: "Ratings", icon: <i className={"icon icon-favorite m-1 text-lg " + ActivityInboxHelper.getColorClass(activity.acceptanceStatusId)}></i>,
            content: <RateActivity activity={activity} viewOnly={true} />,
            hidden: activity.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING
        },
        {
            id: 6, title: "Live Recordings (" + activity.recordings?.length + ")", icon: <i className={"icon icon-camera-classic m-1 text-lg "}></i>,
            content: <RecordingModal data={activity.recordings} openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedRecording: rowData })} />
        },
        {
            id: 7, title: "Team Certifications", icon: <i className={"icon icon-medal m-1 text-lg " + PageHelper.getVerificationColor(this.props.liveStreamData.teamsLogInfo.teamInfo?.status)}></i>,
            content: <TeamCertificationItem />
        },
        {
            id: 8, title: "Activity Documents", icon: <i className={"icon icon-document m-1 text-lg"}></i>,
            content: < ActivityDocuments />
        },
        {
            id: 9, title: "Chat" , icon:<i className={"icon icon-message-double m-1 text-lg"} ></i>,
            content: <GeneralChat rscScope={activity.rscScope} activityId={ activity.id} ehsPingId={null} />
        }
        ]
        return menu;
    }
    currentItemUpdated = (id) =>
    {
        const activity = this.props.activityData.activity;
        console.info('currentItemUpdated', id)
        this.props.setChat(activity.id, activity.rscScope, null)
    }

    downloadActivitySession = () => {
        if ((this.state.allDocument || []).length === 0) {
            toast.error("The session has no document to download", { containerId: "AD", position: toast.TOP_CENTER });
            return;
        }
        const filteredDocuments = WorkplanHelper.filterFiles(this.state.downloadFilter, this.state.allDocument);
        this.setState({ openDialogFilter: false, openDialogDownloadBar: true, percentageArr: PageHelper.initializePercentageArr(filteredDocuments), isLoading: true, filteredDocuments });
        const onProgress = (progressEvent, file) => {
            let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
            let percentageArr = [...this.state.percentageArr];
            const index = percentageArr.indexOf(file);
            percentageArr[index].percentage = percentage;
            this.setState({ percentageArr });
        }
        PageHelper.downloadBlobAzure(filteredDocuments, onProgress)
            .then(response => {
                const fileArr = (response || []).filter(r => r).map(item => ({ contentAsBlob: item.contentAsBlob, copySource: item.copySource }));
                this.downloadAsZip(fileArr);
            })
            .catch(error => {
                this.setState({ openDialogDownloadBar: false, isLoading: false });
                toast.error(constants.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER })
            });
    }

    getReport = () => {
        let activityList = { ...this.props.activityData.activity }
        let SubActivities = activityList.subactivities
        SubActivities.map(item => {
            item["completed"] = item["completed"] ? 1 : 0
            item["sessionAcceptance"] = item["sessionAcceptance"] == null ? 0 : 1
            item["documents"].map(item2 => {
                item2.name["name"] ? item2.name = item2.name["name"] : item2.name = item2.name
                item2["sessionAcceptanceStatus"] = item2["sessionAcceptanceStatus"] ? parseInt(item2["sessionAcceptanceStatus"]) : 0;
            })
        })
        const Activities = {
            activityId: activityList.id,
            activityName: activityList.name,
            aspTeamLead: activityList.aspCompanyAndTeamLead[1],
            documents: activityList.activityDocuments,
            numberOfItems: 1,
            numberOfSnags: activityList.noOfSnags ? activityList.noOfSnags : 0,
            numberRejected: 0,
            resubmittedCount: 0,
            rscEngineer: activityList.rscPersonName,
            rscStatus: activityList.acceptanceStatusId,
            siteCode: activityList.siteName,
            subActivities: activityList.subactivities,
            recordings: activityList.recordings,
            rscScope: activityList.rscScope,
            sessionId: activityList.sessionId,
            sessionType: activityList.sessionType,
            tableRef: activityList.tableRef,
            workPlanId: activityList.workPlanId,
            workPlanName: activityList.workPlanName
        };
        let activitiesArray = [];
        activitiesArray.push(Activities)
        if (this.props.navbarData.reportType === "photoReport") {
            if (activitiesArray[0].rscStatus != 1) {
                this.setState({
                    openDialogDownload: false,
                    exportDone: false,

                })
                var DD = new Date();
                const _fileName_ = "PhotoReport-" + activityList.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".xlsx";
                const fileName = _fileName_.split(' ').join('-');
                toast.success("Preparing the report, once ready it will be sent to you through email. You can navigate but please don't close the window!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
                ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/ExportActivityToExcel", { SubActivities: activitiesArray, UserId: parseInt(this.props.userData.userData.userId), BlobName: fileName }, {}, 3000000
                ).then(response => {
                    if (response.data.result) {
                        toast.dismiss();
                        toast.success("The report is generated and will be sent to your e-mail soon!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
                    }
                })
                    .catch(error => {
                        toast.error("An error occured, try again later!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
                    })

            }

            else {
                toast.error("Activity must be approved or rejected to get the photo report", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });

            }
        }
        // Enhancing the user experience by disabling the "auto-download" for pdf files and showing pdf files as web-pages.
        else if (this.props.navbarData.reportType === "observationSheet" && (activitiesArray[0].rscStatus == 2 || activitiesArray[0].rscStatus == 3)) {
            let newTab = window.open('/ObservationSheet');
            toast.success("The observation sheet is being generated!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            var DD = new Date();
            var fileName = "ObservationSheet-" + activityList.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".pdf";
            ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GenerateObservationSheetForActivity", { SubActivities: activitiesArray }
            ).then(response => {
                if (response.data != "" && response.data != null) {
                    const byteCharacters = atob(response.data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var blob = new Blob([byteArray], { type: 'application/pdf' });
                    newTab.location.href = URL.createObjectURL(blob);
                }
            }).catch(error => {
                toast.error("An error occured, please try again later!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            })
        }
        else if (this.props.navbarData.reportType === "observationSheet" && (activitiesArray[0].rscStatus == 1)) {
            toast.error("Activity must be approved or rejected to get the observation sheet", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
        }
        else if (this.props.navbarData.reportType === "detailedObservationSheet" && (activitiesArray[0].rscStatus == 2 || activitiesArray[0].rscStatus == 3)) {
            let newTab = window.open('./DetailedObservationSheet');
            var DD = new Date();
            var fileName = "DetailedObservationSheet-" + activityList.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".pdf";
            toast.success("The detailed observation sheet is being generated!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GenerateDetailedObservationSheetActivity", { SubActivities: activitiesArray }
            ).then(response => {
                //  toast.success("The detailed observation sheet is being generated!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
                if (response.data != "" && response.data != null) {
                    //var FileSaver = require('file-saver');
                    const byteCharacters = atob(response.data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    var blob = new Blob([byteArray], { type: 'application/pdf' });
                    //FileSaver.saveAs(blob, fileName)
                    newTab.location.href = URL.createObjectURL(blob);

                }
            }).catch(error => {
                toast.error("An error occurred, please try again later!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER })
            })

        }
        else if (this.props.navbarData.reportType === "detailedObservationSheet" && (activitiesArray[0].rscStatus == 1)) {
            toast.error("Activity must be approved or rejected to get the observation sheet", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
        }
        else {
            if (activitiesArray[0].rscStatus == 1) {
                toast.error("Activity must be approved or rejected to get the snags report", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            }
            else {
                var DD = new Date();
                var fileName = "SnagsReport-" + activityList.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".xlsx";
                toast.success("The snags report is being generated!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
                ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/ExportSnagReportActivity", { SubActivities: activitiesArray, userId: parseInt(this.props.userData.userData.userId) }
                ).then(response => {
                    if (response.data != "" && response.data != null) {
                        toast.success("The report is sent to your email you can check your inbox!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
                        var FileSaver = require('file-saver');
                        const byteCharacters = atob(response.data.result);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        var blob = new Blob([byteArray], { type: 'application/xlsx' });
                        FileSaver.saveAs(blob, fileName)
                    }
                }
                ).catch(error => {
                    toast.error("An error occurred, please try again later!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER })
                })
            }
        }

    }

    downloadAsZip = (fileArr) => {
        const session = this.props.activityData.activity;

        let pathArr = [];
        let path = "";
        const activityName = session.id + "-" + session.name.replace(/[\/\\":*?<>|]/g, '');
        fileArr.map((item, index) => {
            const document = this.state.allDocument.find(d => (d.documentPath || d.recordPath) === item.copySource);
            if (!document) {
                return;
            }
            else if (document.documentPath) {
                const checklistName = session.subactivities.find(s => s.id === document.subactivityId)?.name?.replace(/[\/\\":*?<>|]/g, '');
                const acceptanceStatus = constants.acceptanceStatusLookup[document.acceptanceStatusId];
                path = activityName + "\\" + checklistName + "\\" + acceptanceStatus + "\\" + document?.name?.name;
            } else {
                path = activityName + "\\" + "Live Streaming Recordings" + "\\" + document.recordName + ".mp4";
            }
            pathArr = [...pathArr, { path, contentAsBlob: item.contentAsBlob }];
        });

        const zipArr = PageHelper.getZipArr(pathArr);

        Promise.all(zipArr.map(async (item, index) => {
            try {
                if (Object.keys(item.files)?.length === 0) {
                    return;
                }
                const content = await this.generateZip(item);
                saveAs(content, activityName + "-" + (index + 1) + ".zip");
                this.setState({ isLoading: false, compressPercentage: 0 },
                    () => { toast.success((index + 1) + ". " + " session package downloaded successfully.", { containerId: "AD", position: toast.POSITION.TOP_CENTER }); }
                );
            } catch (error) {
                this.setState({ isLoading: false, compressPercentage: 0 },
                    () => { toast.error("Some issues occured while zipping, Please try with less data.", { containerId: "AD", position: toast.POSITION.TOP_CENTER }); }
                );
            }
        })).then(response => {
            this.setState({ openDialogDownloadBar: false, compressPercentage: 0 });
        }).catch(error => {
            this.setState({ openDialogDownloadBar: false, compressPercentage: 0 });
        });

    }

    async generateZip(zip) {
        const updateCallback = (metadata) => {
            this.setState({ compressPercentage: metadata.percent.toFixed(0) });
        }
        return await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: {
                level: 5
            }
        }, updateCallback);
    }

    handleDownloadFilter = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value || target.checked;

        const filter = { ...this.state.downloadFilter };
        filter[name] = value;

        this.setState({
            downloadFilter: filter
        });
    }

    syncErisite = () => {
        const activity = this.props.activityData.activity;
        this.setState({ isLoading: true, openDialogErisite: false }, () => {
            const erisiteActivity = this.state.erisiteActivity;
            let visitor = CommonHelper.getVisitorData();
            erisiteActivity.userId = parseInt(this.props.userData.userData.userId);
            erisiteActivity.latitude = visitor.latitude;
            erisiteActivity.longitude = visitor.longitude;
            erisiteActivity.logBrowser = visitor.vBrowser;
            erisiteActivity.logIP = visitor.vIP;
            erisiteActivity.logLocation = visitor.vCountry;
            erisiteActivity.logName = constants.erisiteLogName;
            erisiteActivity.logAction = "UPDATE";
            erisiteActivity.interfaceId = constants.interfaceTypes.WEB;
            erisiteActivity.logTypeId = constants.logTypes.ACTIVITY;
            erisiteActivity.logObjectId = activity.id;
            erisiteActivity.externalSystemRecordId = activity.externalSystemRecordId;
            erisiteActivity.logDetail = "RSC Scope: " + activity.rscScope + "   |   " +
                "Assigned Group: " + activity.assignedGroup + "   |   " +
                "Assigned Individual: " + activity.assignedIndividual + "   |   " +
                "Erisite Forecast Start: " + activity.erisiteForecastStart + "   |   " +
                "Erisite Forecast End: " + activity.erisiteForecastEnd + "   |   " +
                "Eritop Planned Start: " + activity.plannedStart + "   |   " +
                "Eritop Planned End: " + activity.plannedEnd + "   |   " +
                "Erisite Status: " + activity.erisiteStatus;
            const api = activity.externalSystemRecordId ? 'SiteTracker/SyncForecastDatesBySiteTracker' : 'Erisite/SyncForecastDatesByErisite'
            ApiClient.post(api, erisiteActivity).then(response => {
                if (response.data.status) {
                    activity.rscScope = erisiteActivity.sessionType;
                    activity.assignedGroup = erisiteActivity.assignedWorkgroup;
                    activity.assignedIndividual = WorkplanHelper.getAssignedIndividual(erisiteActivity.assigned);
                    activity.erisiteForecastStart = erisiteActivity.forecastStartDate;
                    activity.erisiteForecastEnd = erisiteActivity.forecastEndDate;
                    activity.plannedStart = DateUtils.toUtcDate(erisiteActivity.forecastStartDate);
                    activity.plannedEnd = DateUtils.toUtcDate(erisiteActivity.forecastEndDate);
                    activity.forecastConfirmationDate = DateUtils.toUtcDate(new Date());
                    activity.forecastConfirmationUserId = erisiteActivity.userId;
                    activity.forecastConfirmationUserName = this.props.userData.userData.username;
                    activity.erisiteStatus = erisiteActivity.status;
                    activity.erisiteActualStart = erisiteActivity.actualStartDate;
                    activity.erisiteActualEnd = erisiteActivity.actualEndDate;
                    this.props.setActivity(activity);
                    toast.success("Erisite syncronization has completed successfully.", { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                } else {
                    toast.error(response.data.errorMessage, { containerId: "AD", position: toast.TOP_CENTER });
                }
                this.setState({ isLoading: false });
            }).catch(error => {
                this.setState({ isLoading: false });
            })
        })
    }

    getErisiteButton = () => {
        const activity = this.props.activityData.activity;
        if (
            //if (constants.erisiteStatuses.filter(item => item.actualUpdate)
            //    .map(s => s.text)
            //    .includes(activity.erisiteStatus)
            //    && activity.actualStart
            //    && activity.actualEnd
            (activity.systemrecordid || activity.externalSystemRecordId)
            //&& activity.erisiteStatus != constants.erisiteStatusesLookup.NOT_APPLICABLE
            && (activity.actualStart || activity.actualEnd)
            && ![constants.rscScopeName, constants.rscScopeEhsName].includes(activity.rscScope)
            && !activity.erisiteActualEnd
            && activity.acceptanceStatusId != constants.acceptanceStatusTypes.PENDING
        ) {
            return <button className={"btn primary" + (this.state.isLoading ? " loading disabled " : "") + (this.state.disable ? " disabled " : "")}
                onClick={() => this.updateErisite()} >
                    {
                        activity.externalSystemRecordId ? 'Sitetracker Update' : 'Erisite Update'
                    }
            </button>
        }
        return null;
    }

    getSyncButton = () => {
        const activity = this.props.activityData.activity;
        if ((activity.systemrecordid || activity.externalSystemRecordId)
            && activity.acceptanceStatusId !== constants.acceptanceStatusTypes.ACCEPTED) {
            return <>
                <button className={"btn primary " + (this.state.isLoading ? " loading disabled " : "") + (this.state.disable ? " disabled " : "")}
                    onClick={() => this.getErisiteActivity()}
                    style={{ margin: 5 }}
                >{
                    activity.externalSystemRecordId? 'Sync SiteTracker' : 'Sync Erisite'
                }
                </button>
                <div className="vertical-line"></div>
            </>;
        }
        return null;
    }
    getRevertRSCApprovalButton = () => {
        var jobRole = this.props.userData?.userData?.jobRole
         if(jobRole != constants.userRoles.ERICSSON_IM) {
           return null
         }
        const activity = this.props.activityData.activity;
        if (activity.rscScope == constants.rscScopeQA
            && activity.acceptanceStatusId == constants.acceptanceStatusTypes.ACCEPTED) {
            return <>
                <button className={"btn primary " + (this.state.isLoading ? " loading disabled " : "") + (this.state.disable ? " disabled " : "")}
                    onClick={() => this.revertRSCApprovals()}
                    style={{ margin: 5 }}
                >{
                   'Revert RSC Approvals'
                }
                </button>
                <div className="vertical-line"></div>
            </>;
        }
        return null;
    }

    updateErisite = () => {
        const activity = this.props.activityData.activity;
        const request = {
            activityId: activity.id,
            systemRecordId: activity.systemrecordid?.toString(),
            assignedWorkGroup: activity.assignedGroup,
            actualStartDate: activity.actualStart,
            actualEndDate: activity.actualEnd,
            updateUserId: parseInt(this.props.userData.userData.userId),
            acceptanceStatusId: activity.acceptanceStatusId,
            resourceId: activity.resourceId,
            externalSystemRecordId: activity.externalSystemRecordId
        };
        this.setState({ isLoading: true, selectedItem: 2 }, () => {
        const api = activity.externalSystemRecordId ? 'SiteTracker/UpdateActivityChecklistsAndActualDates' : 'Erisite/UpdateActivityChecklistsAndActualDates'
        ApiClient.post(api, request, {}, 1200000)
                .then(response => {
                    if (response.data.status) {
                        toast.success(response.data.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                        if (activity.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED) {
                            activity.erisiteActualStart = activity.actualStart;
                            activity.erisiteActualEnd = activity.actualEnd;
                            activity.erisiteStatus = constants.erisiteStatusesLookup.COMPLETED;
                        }
                    } else {
                        toast.error(response.data.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                    }
                    activity.integrationUserId = this.props.userData.userData.userId;
                    activity.integrationUserName = this.props.userData.userData.username;
                    activity.integrationStatus = response.data.status ? 1 : 0;
                    activity.integrationDate = DateUtils.toUtcDate(new Date());
                    activity.integrationOutput = response.data.errorMessage;

                    this.props.setActivity(activity);
                    this.setState({ isLoading: false, selectedItem: 2 });
                }).catch(error => {
                    this.setState({ isLoading: false, selectedItem: 2 });
                    toast.error(constants.erisiteUpdateError, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                });
        });
    }
    revertRSCApprovals = () => {
        const activity = this.props.activityData.activity;
        this.setState({ isLoading: true }, () => {
            const api =  'Activity/RevertRSCApprovals' 
            const logInfo = {
                LogName : "REVERT RSC APPROVALS",
                LogAction : "Update",
                LogObjectId : activity.id,
                userId : this.props.userData?.userDetail?.id
            }
            debugger;
            ApiClient.post(api, { id: activity.id , logInfo:logInfo }).then(response => {
                this.setState({ isLoading: false });
                const data = response?.data;
                if(data?.success) {
                activity.acceptanceStatusId = 3 ;
                 this.props.setActivity(activity);
                 
                // this.getActivityDetails(activity.id);
                }
                else {
                    toast.error(data.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });   
                }
            }).catch(error => {
                this.setState({ isLoading: false });
                toast.error(constants.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
            })
        });
    }
    getActivityDetails = (activityId) => {
        
        ///console.log("selectedActivity getActivityDetails",selectedActivity)

        this.setState({ isLoading: true });
        ApiClient.post("Workplan/GetActivityDetails", { id: activityId })
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    const activity = response.data;
                    const type = this.props.type;
                  //  WorkplanHelper.mapActivity(activity, selectedActivity, type);
                    this.props.setActivity(activity);

                });
                ///console.log("getActivityDetails today",response.data)  
            })
            .catch(error => {
                console.error(error);;
                this.setState({ isLoading: false });
            });
    }
    getErisiteActivity = () => {
        const activity = this.props.activityData.activity;
        this.setState({ isLoading: true }, () => {
            const api = activity.externalSystemRecordId ? 'SiteTracker/GetActivityBySystemRecordIdAndUpdateHighRisk' : 'Erisite/GetActivityBySystemRecordIdAndUpdateHighRisk'
            ApiClient.post(api, { ExternalSystemRecordId: activity.externalSystemRecordId || '', SystemRecordId: activity.systemrecordid || 0 , ActivityId: activity.id }).then(response => {
                const erisiteActivity = response.data?.data ? response.data.data[0] : null;
                if (!erisiteActivity) {
                    toast.error("There is no activity with related system record ID in Erisite!", { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                    this.setState({ disable: true, isLoading: false });
                } else {
                    this.setState({ erisiteActivity, isLoading: false, openDialogErisite: true });
                }
            }).catch(error => {
                this.setState({ isLoading: false });
                toast.error(constants.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
            })
        });
    }

    render() {
        const completedSize = this.state.percentageArr.filter(d => d.percentage === 100).length;
        const totalSize = this.state.filteredDocuments.length;
        const activity = this.props.activityData.activity;
        return (<EuiTile fullscreen tileStyle={{ padding: 0 }}>

            <EuiBreadcrumb id="activityBreadcrumb"
                parentName="Home"
                childName={<div className="flex-start-content">
                    {activity.type !== constants.activityListTypes.ACTIVITY ? <span>{activity.workplanName}<i className="icon icon-chevron-right m-1"></i> </span> : null}
                    {activity.id + " - " + activity.name}
                    <span class="tooltip pointer">
                        <i className="icon icon-undo m-1 icon-lg" onClick={() => this.props.history.goBack()}></i>
                        <span class="message right">Return to Previous Page</span>
                    </span>
                </div>}
                onClickParent={() => this.props.changeActivityMod(constants.pageMods.LIST)}
                className="p-2" />

            <MenuMultiPanelTile id="activityDetailsMenuMultiPanel"
                menuList={this.getMenuList()}
                key={this.state.isLoading}
                selectedItem={this.state.selectedItem}
                currentItemUpdated={this.currentItemUpdated}
            />

            <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                handleClose={() => this.setState({ openDialogPreview: false })}
                id="activityRecordingsDocumentView"
                showLocation={false}
                selectedDocument={{ ...this.state.selectedRecording, documentPath: SasHelper.addSasKey(this.state.selectedRecording.recordPath, this.props.sasToken), uploadDate: this.state.selectedRecording.endDatetime, type: 2 }}
            >
            </DocumentDialogModal>

            <DialogModal
                content={<DocumentLoadingModal compressPercentage={this.state.compressPercentage}
                    session={activity}
                    length={totalSize}
                    percentageArr={this.state.percentageArr} />}
                customStyle={{ overflow: "hidden" }}
                handleClose={() => this.setState({ openDialogDownloadBar: false })}
                id="activityUploadBarDialog"
                open={this.state.openDialogDownloadBar}
                buttonsVisible="false"
                title={"Downloading Progress: " + CommonHelper.getPercentage(completedSize, totalSize)}
                closeButtonVisible="false"
                disableBackdropClick={true}
            >
            </DialogModal>

            <DialogModal
                buttonName="Ok"
                customStyle={{ width: 700 }}
                content={<DownloadFilterModal
                    downloadFilter={this.state.downloadFilter}
                    handleDownloadFilter={this.handleDownloadFilter}
                    allDocumentTypeSizes={this.state.allDocumentTypeSizes} />}
                handleClose={() => this.setState({ openDialogFilter: false })}
                id="downloadSessionFilter"
                onClick={this.downloadActivitySession}
                open={this.state.openDialogFilter}
                title={"Filter Download Session | " + (this.state.allDocumentTypeSizes.total === 0 && this.state.allDocument.length > 0 ? "Please wait total size calculating..."
                    : " Total Size: " + CommonHelper.bytesToSize(this.state.allDocumentTypeSizes.total))}
                type="primary"
            >
            </DialogModal>

            <DialogModal
                buttonName="Generate Report"
                content={<Report />}
                handleClose={() => this.setState({ openDialogDownload: false })}
                id="reportDialog"
                onClick={this.getReport}
                open={this.state.openDialogDownload}
                title="Download"
                type="primary"
                titleId="ReportsTitle"
            >
            </DialogModal>

            <DialogModal
                actionStyle={{ padding: 0 }}
                buttonName="Confirm"
                contentStyle={{ paddingBottom: 0 }}
                customStyle={{ width: 700 }}
                content={<ErisiteUpdateActivity title={ activity.externalSystemRecordId ? 'SiteTracker': 'Erisite'} erisiteActivity={this.state.erisiteActivity} />}
                handleClose={() => this.setState({ openDialogErisite: false })}
                id="erisiteSyncDialog"
                onClick={this.syncErisite}
                open={this.state.openDialogErisite}
                title={
                    activity.externalSystemRecordId ? 'Please confirm syncronization of below fields with SiteTracker!' 
                    : 'Please confirm syncronization of below fields with Erisite!'}

                type="primary"
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'AD'} autoClose={2500} />
        </EuiTile >);
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user, navbarData: state.navigation, activityData: state.activity, liveStreamData: state.liveStream, sasToken: state.complaints.sasToken});
const mapDispatchToProps = dispatch => {
    return {
        changeActivityMod: (mod) => dispatch({ type: "CHANGE_ACTIVITY_MOD", payload: mod }),
        setEhsPing: (ehsPing) => dispatch({ type: "SET_EHS_PING", payload: ehsPing }),
        changeEhsPingMod: (mod) => dispatch({ type: "CHANGE_EHS_PING_MOD", payload: mod }),
        setFilteredEhsPings: (filteredEhsPings) => dispatch({ type: "SET_FILTERED_EHS_PINGS", payload: filteredEhsPings }),
        setEhsPingFilter: (filter) => dispatch({ type: "SET_EHS_PING_FILTER", payload: filter }),
        getReport: reportType => dispatch({ type: "GET_REPORT", payload: reportType }),
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
        setChat: (activityId, rscScope, ehsPingId) => dispatch(setChat(activityId, rscScope, ehsPingId)),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivityDetails));

