import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiCheckbox from "../../eui-components/checkbox";
import FormField from "../../eui-components/formfield";
import EuiRow from "../../eui-components/row";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import EuiTextField from "../../eui-components/textfield";
import constants from "../../utils/constants";

class AddCheckListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLiveOptions: [{ value: 0, text: "No" }, { value: 1, text: "Yes" }],
            nameLengthViolation: false
        }
    }

    handleChange = (e) => {
        const item = { ...this.props.activityTemplateData.item }
        const target = e.target;
        const name = target.name;
        if (name == 'name') {
            if (target.value.length > 255) {
                this.setState({
                    nameLengthViolation: true
                });
                var trimmedValue = target.value.substring(0, 254);
                item[name] = trimmedValue;
            }
            else {
                this.setState({
                    nameLengthViolation: false
                });
                item[name] = target.value;
            }
        }
        else if (name == "itemSortId" || name == "itemCatSortId")
            item[name] = parseInt(target.value);

        else if (name == 'severity') {
            var textValue = constants.templateSeverityLevels.find(item => item.value == target.value)?.text;
            item[name] = textValue

        }
        else if (name == "groupId") {
            item[name] = target.value;
        }
        else if (name == "isLiveItem")
            item[name] = (target.value ? 1 : 0)
        else if (name == "viCodeId") {
            item[name] = target.value || null;
        }
        else
            item[name] = target.value;
        this.props.setItem(item);

    }

    fileChange = (e) => {
        const files = e.target.files;
        let reader = new FileReader();
        if (files.length !== 0) {
            reader.onload = () => {
                const data = reader.result;
                const item = { ...this.props.activityTemplateData.item };
                item["templatePath"] = data;
                item["fileName"] = files[0].name;
                this.props.setItem(item)
            };
            reader.readAsDataURL(files[0]);
        }
    }



    render() {
        return (
            <EuiRow>
                <EuiRow className="w-10"  >
                    <FormField className="w-10 mt-2 mb-2">
                        <EuiTextField className="w-10" fullwidth id="itemName" name="name" label="Checklist Item Name" labelStyle={{ fontSize: "14px", color: "gray" }}
                            value={this.props.activityTemplateData.item.name} onChange={(e) => this.handleChange(e)}
                            multiLine={true}
                            labelClass="color-gray required"
                            style={{ height: "60px" }}
                        />
                        <label style={{ color: 'red' }}>*Checklist item name cannot exceed 255 characters</label>
                    </FormField>
                    <div className="column sm-6 md-6 xl-6">
                        <FormField className="w-10 mt-2 mb-2">
                            <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="groupName">Group Name</label>
                            <EuiAutocompleteSelect className="w-10"
                                key={this.props.activityTemplateData?.itemsGroups}
                                fullwidth
                                id="checklistItemsGroupNameSelect"
                                name="groupId"
                                value={this.props.activityTemplateData.item?.groupId || 0}
                                optionListStyle={{ width: "100%" }}
                                options={this.props.activityTemplateData?.itemsGroups || []}
                                onChange={(e) => this.handleChange(e)} />
                        </FormField>
                        <FormField className="w-10 mt-2 mb-2">
                            <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="viCodeId">Vi Code</label>
                            <EuiAutocompleteSelect className="w-10"
                                id="checklistItemsViCodeSelect"
                                key={this.props.activityTemplateData?.viCodes}
                                fullwidth name="viCodeId"
                                value={this.props.activityTemplateData.item?.viCodeId || 0}
                                optionListStyle={{ width: "100%" }}
                                options={this.props.activityTemplateData?.viCodes || []}
                                onChange={(e) => this.handleChange(e)} />
                        </FormField>
                        <FormField className="w-10 mt-2 mb-2">
                            <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="severitySelect">Severity</label>
                            <EuiAutocompleteSelect
                                key={constants.templateSeverityLevels}
                                className="w-10"
                                label="Severity"
                                id="severitysSelect"
                                optionListStyle={{ width: "100%" }}
                                name="severity"
                                options={constants.templateSeverityLevels}
                                value={constants.templateSeverityLevels.find(item => item.text == this.props.activityTemplateData.item?.severity)?.value}
                                onChange={(e) => this.handleChange(e)}
                                fullwidth
                            />
                        </FormField>

                        <EuiRow>
                            <FormField className="w-5 mt-2 mb-2">
                                <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="itemSortId">Category Sort ID</label>
                                <input type="number" style={{ width: "100px" }} className="w-10" fullwidth id="itemcatSortId" name="itemCatSortId"
                                    value={this.props.activityTemplateData.item.itemCatSortId} onChange={(e) => this.handleChange(e)} />
                            </FormField>

                            <FormField className="w-5 mt-2 mb-2">
                                <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="itemSortId">Item Sort ID</label>
                                <input type="number" style={{ width: "100px" }} className="w-10" fullwidth id="itemSortId" name="itemSortId"
                                    value={this.props.activityTemplateData.item.itemSortId} onChange={(e) => this.handleChange(e)} />
                            </FormField>
                        </EuiRow>
                        <FormField className="w-10 mt-2 mb-2">
                            <EuiCheckbox
                                label="Is Live"
                                labelClass="color-gray"
                                className="flex-start-content pl-0 pr-1"
                                id="isLive"
                                refValue={this.props.activityTemplateData.item.isLive}
                                onChange={(e) => this.handleChange(e)}
                                defaultChecked={false}
                                checked={this.props.activityTemplateData.item.isLive}
                                name="isLive" />
                        </FormField>
                        { /*
                        <FormField className="w-10 mt-2 mb-2">
                            <input id="fileInput" className="w-10" style={{ width: "100%" }}
                                label="Upload template for this activity checklist"
                                type="file" id="myFile" name="item"
                                labelstyle={{ fontSize: "14px" }}
                                onChange={(e) => this.fileChange(e)}
                            >
                            </input>
                        </FormField>
                        */}
                    </div>
                    <div className="column sm-6 md-6 xl-6">

                        <FormField className="w-10 mt-2 mb-2">
                            <EuiTextField className="w-10" fullwidth id="subItemNo" name="subItemNo" labelClass="color-gray" label="Sub Item Number" labelStyle={{ fontSize: "14px", color: "gray" }}
                                value={this.props.activityTemplateData.item?.subItemNo} onChange={(e) => this.handleChange(e)} />
                        </FormField>
                        <FormField className="w-10 mt-2 mb-2">
                            <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="workInstructionSelect">Work Instruction</label>
                            <EuiAutocompleteSelect
                                disabled={true}
                                key={this.props.activityTemplateData?.workManuals}
                                className="w-10"
                                label="Select Work Instruction"
                                id="workInstructionSelect"
                                optionListStyle={{ width: "100%" }}
                                name="workInstructionId"
                                options={this.props.activityTemplateData?.workManuals}
                                value={this.props.activityTemplateData.selectedTemplate?.workInstructionId}
                                onChange={(e) => this.handleChange(e)}
                                fullwidth
                            />
                        </FormField>



                    </div>



                </EuiRow >

            </EuiRow >
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, activityTemplateData: state.activityChecklists });
const mapDispatchToProps = dispatch => {
    return {
        setItem: (item) => dispatch({ type: "SET_ITEM", payload: item }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddCheckListItem));

