import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DialogModal from "../../components/dialog";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import EuiTile from "../../eui-components/tile";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ApiClient from "../../utils/api-client";
import ChecklistHelper from "../../utils/checklist-helper";
import constants from "../../utils/constants";
import AddChecklistItem from "./addChecklistItem";
import { Notification } from '@eds/vanilla';
import DateUtils from "../../utils/date-utils";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import FormField from "../../eui-components/formfield";
import EuiCheckbox from "../../eui-components/checkbox";


class ActivityChecklistItemsSubList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activityCheckLists: [],
            columns: [
                {
                    title: "ID",
                    key: "id",
                    width: "4%",
                    cellStyle: "white-space:nowrap; font-size: 13px",
                    sort: "none"
                },
                {
                    title: "Group name",
                    onCreatedCell: (td, cellData) => {
                        if (cellData)
                            td.innerHTML = this.props.activityTemplateData?.itemsGroups?.find(item => item.value == cellData)?.text;
                    },
                    key: "groupId",
                    width: "15%",
                    sort: "none"
                },
                {
                    title: "Checklist Item",
                    key: "name",
                    width: "20%",
                    sort: "none"

                },
                {
                    title: "Severity",
                    key: "severity",
                    width: "4%",
                    sort: "none"
                },
                {
                    title: "VI Code",
                    key: "viCodeId",
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            td.innerHTML = this.props.activityTemplateData?.viCodes?.find(item => item.value == cellData)?.text || "";
                        }
                    },
                    width: "5%",
                    sort: "none"
                },
                {
                    title: "Last Modified User",
                    key: "lastModifiedUserName",
                    width: "10%",

                    sort: "none"
                },
                {
                    title: "Last Modified Date",
                    key: "updatedAt",
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            var formattedDate = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm")
                            td.innerHTML = formattedDate;
                        }
                    },
                    width: "10%",
                    sort: "none"
                },
                {
                    title: "",
                    key: "editId",
                    hideFilter: true,
                    onCreatedCell: (td, cellData) => {
                        if ((this.props.activityTemplateData.selectedTemplate.userId == parseInt(this.props.userData.userData.id) || this.props.activityTemplateData.selectedTemplate.publicTemplate) && !this.props.activityTemplateData.selectedTemplate.deleteDate) {
                            td.innerHTML = `<span id="edit_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable  "></i><span class="message top-end">Edit Checklist Item</span></span>`;

                            td.querySelector("#edit_").addEventListener('click', () => this.openEditDialog(cellData))
                        }
                        else
                            td.innerHTML = "";
                    },
                    width: "1%",
                },
                {
                    title: "",
                    onCreatedCell: (td, cellData) => {
                        if ((this.props.activityTemplateData.selectedTemplate.userId == parseInt(this.props.userData.userData.id) || this.props.activityTemplateData.selectedTemplate.publicTemplate) && !this.props.activityTemplateData.selectedTemplate.deleteDate) {
                            td.innerHTML = `<span id="delete_" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete Checklist Item</span></span>`;
                            td.querySelector("#delete_").addEventListener('click', () => this.openDeleteDialog(cellData))
                        }

                        else
                            td.innerHTML = "";

                    },
                    hideFilter: true,
                    key: "deleteId",
                    width: "1%",
                },
            ],
            openAddDialog: false,
            openDeleteDialog: false,
            openImportDialog: false,
            openImportEditDialog: false,
            openEditDialog: false,
            openOptionsDialog: false,
            skipNulls: false,
            selectedOption: "createTemplate"

        }
    }

    componentDidMount() {
        if (this.props.activityTemplateData.selectedTemplate.items) {
            this.setState({
                isLoading: false
            })
        }
    }

    deleteItem = () => {
        const selectedTemplate = { ... this.props.activityTemplateData.selectedTemplate };
        const item = { ...this.props.activityTemplateData.item };
        selectedTemplate['items'] = [...selectedTemplate.items.filter(i => i.id !== item.id)]
        this.setState({
            isLoading: true, openDeleteDialog: false
        },
            () => {
                ApiClient.post("ActivityTemplate/DeleteTemplateItem", item)
                    .then(response => {
                        if (response.data) {
                            this.props.setSelectedTemplate(selectedTemplate);
                            this.props.setItem({});
                            this.setState({
                                isLoading: false,
                            });
                            const notification = new Notification({
                                title: 'Delete Activity Template',
                                description: 'Template deleted successfully',
                                timeout: 5000,
                                icon: 'icon-check'
                            });
                            notification.init();
                        }
                    }).
                    catch(error => {
                        alert(error);
                        this.setState({
                            isLoading: false,
                        })
                    })
            })
    }

    openDeleteDialog = (id) => {
        const selectedTemplate = { ... this.props.activityTemplateData.selectedTemplate };
        const item = { ...selectedTemplate.items.find(i => i.id == id) }
        this.props.setItem(item);

        this.setState({
            openDeleteDialog: true
        })
    }

    openEditDialog = (id) => {
        const selectedTemplate = { ... this.props.activityTemplateData.selectedTemplate };
        const item = { ...selectedTemplate.items.find(i => i.id == id) }

        this.props.setItem(item);

        this.setState({
            openEditDialog: true
        })
    }

    openAddDialog = () => {
        this.props.setItem({});
        this.setState({ openAddDialog: true });
    }

    addEditItem = () => {
        const selectedTemplate = { ... this.props.activityTemplateData.selectedTemplate };
        const item = { ...this.props.activityTemplateData.item }
        item['SubactivityTemplateId'] = parseInt(selectedTemplate.id);
        item['lastModifiedUser'] = parseInt(this.props.userData.userData.id);
        if (!item.name || !item.groupId || !item.itemCatSortId || !item.itemSortId || !item.severity || !item) {
            const notification = new Notification({
                title: 'Add Checklist Item',
                description: 'Please fill all required data to save',
                timeout: 5000,
                icon: 'icon-triangle'
            });
            notification.init();
        }
        else {
            this.props.setItem(item);
            const notification = new Notification({
                title: 'Add Checklist Item',
                description: 'Item is being saved',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();
            this.setState({ isLoading: true, openAddDialog: false, openEditDialog: false },
                () => {
                    ApiClient.post("ActivityTemplate/AddEditTemplateItem", item)
                        .then(response => {
                            if (response.data) {
                                const items = ChecklistHelper.mapItems(response.data, this.props.activityTemplateData.categories);
                                selectedTemplate['items'] = items;
                                selectedTemplate.items.sort((a, b) => (
                                    ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) === (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity)) ?
                                        (((a.itemSortId ? parseInt(a.itemSortId) : Infinity) === (b.itemSortId ? parseInt(b.itemSortId) : Infinity)) ?
                                            (a.name - b.name)
                                            :
                                            ((a.itemSortId ? parseInt(a.itemSortId) : Infinity) - (b.itemSortId ? parseInt(b.itemSortId) : Infinity)))
                                        :

                                        ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) - (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity))
                                ));
                                this.props.setSelectedTemplate(selectedTemplate);
                                this.setState({
                                    isLoading: false
                                });
                            }
                            ChecklistHelper.mapTemplate(selectedTemplate, this.props.activityTemplateData.categories);
                            selectedTemplate.items.sort((a, b) => (
                                ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) === (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity)) ?
                                    (((a.itemSortId ? parseInt(a.itemSortId) : Infinity) === (b.itemSortId ? parseInt(b.itemSortId) : Infinity)) ?
                                        (a.name - b.name)
                                        :
                                        ((a.itemSortId ? parseInt(a.itemSortId) : Infinity) - (b.itemSortId ? parseInt(b.itemSortId) : Infinity)))
                                    :

                                    ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) - (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity))
                            ));
                            this.props.setSelectedTemplate(selectedTemplate);
                            this.props.changeCheckListMod(constants.pageMods.SUB_LIST);
                        })
                        .catch(error => {
                            alert(error);
                            this.setState({
                                isLoading: false,
                                openAddDialog: false
                            });
                        });
                });
        }
    }

    handleFile = (e) => {
        const files = e.target.files;
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        let reader = new FileReader();
        if (files.length !== 0) {
            files[0].arrayBuffer().then(res => {
                let d = new Uint8Array(res);
                const wb = XLSX.read(d, { type: "array" })
                const wsname = wb.SheetNames[0]
                const worksheet = wb.Sheets[wsname];

                const wbCsv = { Sheets: { data: worksheet }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wbCsv, { bookType: "csv", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                reader.readAsDataURL(data);
                reader.onload = () => {

                    const _data = reader.result;

                    const item = { ...this.props.activityTemplateData.item };
                    item["templatePath"] = _data;
                    item["fileName"] = files[0].name;
                    item['subactivityTemplateId'] = parseInt(this.props.activityTemplateData.selectedTemplate.id);
                    this.props.setItem(item)

                };
            });

        }

    };

    importEditExcel = () => {
        const selectedTemplate = { ... this.props.activityTemplateData.selectedTemplate };
        const item = { ...this.props.activityTemplateData.item };
        item['lastModifiedUser'] = parseInt(this.props.userData.userData.id);
        item['skipNulls'] = this.state.skipNulls;
        this.setState({
            isLoading: true, openEditImportDialog: false, skipNulls: false
        },
            () => {
                ApiClient.post("ActivityTemplate/VerifyAndUpdate", item)
                    .then(response => {
                        if (response.data) {
                            if (response.data.error) {
                                this.setState({
                                    isLoading: false,
                                    openEditImportDialog: false

                                });
                                const notification = new Notification({
                                    title: 'Import Activity Checklist Items',
                                    description: response.data.error,
                                    icon: 'icon-cross'
                                });
                                notification.init();
                            }
                            else {
                                const notification = new Notification({
                                    title: 'Import Activity Checklist Items',
                                    description: "Items Updated Successfully",
                                    timeout: 5000,
                                    icon: 'icon-check'
                                });
                                notification.init();
                                const items = ChecklistHelper.mapItems(response.data.fileData, this.props.activityTemplateData.categories);
                                selectedTemplate['items'] = items;
                                this.props.setSelectedTemplate(selectedTemplate);
                                ChecklistHelper.mapTemplate(selectedTemplate, this.props.activityTemplateData.categories);
                                selectedTemplate.items.sort((a, b) => (
                                    ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) === (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity)) ?
                                        (((a.itemSortId ? parseInt(a.itemSortId) : Infinity) === (b.itemSortId ? parseInt(b.itemSortId) : Infinity)) ?
                                            (a.name - b.name)
                                            :
                                            ((a.itemSortId ? parseInt(a.itemSortId) : Infinity) - (b.itemSortId ? parseInt(b.itemSortId) : Infinity)))
                                        :

                                        ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) - (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity))
                                ));
                                this.props.setSelectedTemplate(selectedTemplate);
                                this.props.changeCheckListMod(constants.pageMods.SUB_LIST);
                                this.setState({
                                    isLoading: false,
                                    openImportDialog: false

                                });

                            }
                        }
                    }).
                    catch(error => {
                        const notification = new Notification({
                            title: 'Import Activity Checklist Items',
                            description: "Use only pre - defined download template to upload items",
                            icon: 'icon-cross'
                        });
                        notification.init();
                        this.setState({
                            isLoading: false,
                        })
                    })
            })

    }

    importExcel = () => {
        const selectedTemplate = { ... this.props.activityTemplateData.selectedTemplate };
        const item = { ...this.props.activityTemplateData.item };
        item['lastModifiedUser'] = parseInt(this.props.userData.userData.id);
        this.setState({
            isLoading: true, openImportDialog: false
        },
            () => {
                ApiClient.post("ActivityTemplate/VerifyAndLoad", item)
                    .then(response => {
                        if (response.data) {
                            if (response.data.error) {
                                this.setState({
                                    isLoading: false,
                                    openImportDialog: false

                                });
                                const notification = new Notification({
                                    title: 'Import Activity Checklist Items',
                                    description: response.data.error,
                                    icon: 'icon-cross'
                                });
                                notification.init();
                            }
                            else {
                                const notification = new Notification({
                                    title: 'Import Activity Checklist Items',
                                    description: "Items Added Successfully",
                                    timeout: 5000,
                                    icon: 'icon-check'
                                });
                                notification.init();
                                const items = ChecklistHelper.mapItems(response.data.fileData, this.props.activityTemplateData.categories);
                                selectedTemplate['items'] = items;
                                this.props.setSelectedTemplate(selectedTemplate);
                                ChecklistHelper.mapTemplate(selectedTemplate, this.props.activityTemplateData.categories);
                                selectedTemplate.items.sort((a, b) => (
                                    ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) === (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity)) ?
                                        (((a.itemSortId ? parseInt(a.itemSortId) : Infinity) === (b.itemSortId ? parseInt(b.itemSortId) : Infinity)) ?
                                            (a.name - b.name)
                                            :
                                            ((a.itemSortId ? parseInt(a.itemSortId) : Infinity) - (b.itemSortId ? parseInt(b.itemSortId) : Infinity)))
                                        :

                                        ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) - (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity))
                                ));
                                this.props.setSelectedTemplate(selectedTemplate);
                                this.props.changeCheckListMod(constants.pageMods.SUB_LIST);
                                this.setState({
                                    isLoading: false,
                                    openImportDialog: false

                                });

                            }
                        }
                    }).
                    catch(error => {
                        const notification = new Notification({
                            title: 'Import Activity Checklist Items',
                            description: "Use only pre - defined download template to upload items",
                            icon: 'icon-cross'
                        });
                        notification.init();
                        this.setState({
                            isLoading: false,
                        })
                    })
            })

    }

    downloadTemplate = () => {
        this.setState({
            openOptionsDialog: false,
            selectedOption: "createTemplate"
        })
        if (this.state.selectedOption == "createTemplate")
            this.downloadCreateTemplate();
        else
            this.downloadEditTemplate();
    }

    prepareDetailsSheet = () => {
        const details = [];
        const groups = this.props.activityTemplateData.itemsGroups.map(item => {
            const element = {
                SubActivityGroupName: item.text,
                ViCode: ""
            };
            return element;
        });
        const viCodes = this.props.activityTemplateData.viCodes.map(item => {
            const element = {
                ViCode: item.text,
                SubActivityGroupName: "",
            };
            return element;
        })

        if (viCodes.length > groups.length) {
            for (var i = 0; i < viCodes.length; i++) {
                details.push({
                    SubActivityGroupName: (groups[i] ? groups[i].SubActivityGroupName : ""),
                    ViCode: viCodes[i].ViCode

                });
            }
        }
        else {
            for (var i = 0; i < groups.length; i++) {
                details.push({
                    SubActivityGroupName: groups[i].SubActivityGroupName,
                    ViCode: (viCodes[i] ? viCodes[i].ViCode : "")
                });
            }
        }
        return details;
    }

    downloadCreateTemplate = () => {
        var DD = new Date();

        const fileName = "new_checklist_template_" + DD.toISOString();
        const _data = [{
            "Name": "", "SubActivityGroupName": "", "SubItemNo": "", "IsLiveItem": "", "IsNotApplicable": "", "ViCode": "", "Severity": "", "ItemCatSortId": "", "ItemSortId": ""
        }];
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const details = this.prepareDetailsSheet();

        const ws = XLSX.utils.json_to_sheet(_data);
        const wsGroups = XLSX.utils.json_to_sheet(details);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        XLSX.utils.book_append_sheet(wb, wsGroups, "details");

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        const notification = new Notification({
            title: "Warning",
            description: 'SubActivityGroupName and ViCode fields must be from the template details sheet!',
            icon: 'icon-triangle-warning'
        });
        notification.init();
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    downloadEditTemplate = () => {
        var DD = new Date();

        const fileName = "exist_checklist_template_" + DD.toISOString();

        const _data = [... this.props.activityTemplateData.selectedTemplate.items.map(item => {
            const element = {
                Id: item.id,
                Name: item.name,
                SubActivityGroupName: this.props.activityTemplateData.itemsGroups?.find(el => el.value == item.groupId)?.text,
                SubItemNo: item.subItemNo,
                ItemSortId: item.itemSortId,
                ItemCatSortId: item.itemCatSortId,
                ViCode: this.props.activityTemplateData.viCodes?.find(el => el.value == item.viCodeId)?.text,
                IsLiveItem: item.isLiveItem,
                Severity: item.severity,
                IsNotApplicable: item.isNotApplicable,
            }
            return element;
        })];

        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const details = this.prepareDetailsSheet();

        const ws = XLSX.utils.json_to_sheet(_data);
        const wsGroups = XLSX.utils.json_to_sheet(details);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        XLSX.utils.book_append_sheet(wb, wsGroups, "details");

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);

    }

    handleChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        });
    }

    render() {
        var DD = new Date();
        let _fileName_ = this.props.activityTemplateData.selectedTemplate.name + "Template-" + DD.toDateString() + "-" + DD.toLocaleTimeString();
        const fileName = _fileName_.split(' ').join('-');
        const data = [... this.props.activityTemplateData.selectedTemplate.items.map(item => {
            const element = {
                Id: item.id,
                Name: item.name,
                SubactivityTemplateName: this.props.activityTemplateData.selectedTemplate.name,
                SubActivityGroupName: this.props.activityTemplateData.itemsGroups.find(el => el.value == item.groupId)?.text,
                SubItemNo: item.subItemNo,
                ItemSortId: item.itemSortId,
                ItemCatSortId: item.itemCatSortId,
                ViCode: this.props.activityTemplateData.viCodes?.find(el => el.value == item.viCodeId)?.text,
                IsLiveItem: item.isLiveItem,
                Severity: item.severity,
                IsNotApplicable: item.isNotApplicable,
                CreatedAt: item.createdAt,
                UpdatedAt: item.updatedAt,
                LastModifiedUserName: item.lastModifiedUserName
            }
            return element;
        })];
        const excelData = data;
        return (<EuiRow key={this.state.isLoading} disabled={this.state.isLoading ? "disabled" : ""}>
            <EuiTile >
                <EuiLoading id="activtyChecklistSubListLoading" isLoading={this.state.isLoading} />
                <EuiBreadcrumb id="checklistItemsBreadCrumn"
                    parentName={<span><i className="icon icon-home" /> Home</span>}
                    childName={this.props.activityTemplateData.selectedTemplate.name}
                    onClickParent={() => this.props.changeCheckListMod(constants.pageMods.LIST)}
                    className="m-2 mb-3" />
                <EuiRow className="w-10">
                    <div className="column sm-2 md-2 xl-2">
                        <EuiTextField className="p-1 w-5"
                            fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="type"
                            label="Template Type" value={this.props.activityTemplateData.selectedTemplate.templatableType} />
                    </div>
                    <div className="column sm-3 md-3 xl-3">
                        <EuiTextField
                            className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="customer"
                            label={(this.props.activityTemplateData.selectedTemplate.templatableType === "Customer" ? "Customer" : "Project")}
                            value={(this.props.activityTemplateData.selectedTemplate.templatableType === "Customer" ?
                                this.props.activityTemplateData.customers?.find(c => c.value == this.props.activityTemplateData.selectedTemplate.templatableId)?.text
                                : this.props.activityTemplateData.projects?.find(c => c.value == this.props.activityTemplateData.selectedTemplate.templatableId)?.text
                            )} />
                    </div>
                    <div className="column sm-7 md-7 xl-7">
                        <EuiTextField className="p-1 w-10"
                            multiLine
                            fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="discription"
                            label="Description" value={this.props.activityTemplateData.selectedTemplate.description} />
                    </div>
                </EuiRow>
                <ObseleteEuiTable
                    style={{ marginLeft: "15px" }}
                    //height="65%"
                    classes="tiny striped"
                    columns={this.state.columns}
                    data={this.props.activityTemplateData.selectedTemplate.items}
                    id="activityChecklistSubList"
                    hideSearch={true}
                    sortable={true}
                    filter={true}
                    paginated={true}
                    rowsPerPage={8}
                    viewActionButton={true}

                    actionButton={<span>
                        <button hidden={((this.props.userData.userData.id != this.props.activityTemplateData.selectedTemplate.userId && !this.props.activityTemplateData.selectedTemplate.publicTemplate) || this.props.activityTemplateData.selectedTemplate.deleteDate ? true : false)}
                            id="addTemplate"
                            className="btn primary ml-10"
                            onClick={() => this.openAddDialog()}>{<span > <i class="icon icon-plus"></i> Checklist item</span>}</button>
                        <button hidden={((this.props.userData.userData.id != this.props.activityTemplateData.selectedTemplate.userId && !this.props.activityTemplateData.selectedTemplate.publicTemplate) || this.props.activityTemplateData.selectedTemplate.deleteDate ? true : false)}
                            id="downloadTemplate"
                            className="btn primary ml-10"
                            //onClick={() => this.downloadTemplate()}
                            onClick={() => this.setState({ openOptionsDialog: true })}

                        >
                            {<span > <i className="icon icon-download-save"></i> Download Template</span>}
                        </button>
                        <button hidden={((this.props.userData.userData.id != this.props.activityTemplateData.selectedTemplate.userId && !this.props.activityTemplateData.selectedTemplate.publicTemplate) || this.props.activityTemplateData.selectedTemplate.deleteDate ? true : false)}
                            id="uploadEdit"
                            className="btn primary ml-10"
                            //onClick={() => this.downloadTemplate()}
                            onClick={() => this.setState({ openEditImportDialog: true })}

                        >
                            {<span > <i className="icon icon-edit"></i>Bulk Update</span>}
                        </button>

                        <button hidden={((this.props.userData.userData.id != this.props.activityTemplateData.selectedTemplate.userId && !this.props.activityTemplateData.selectedTemplate.publicTemplate) || this.props.activityTemplateData.selectedTemplate.deleteDate ? true : false)}
                            className="btn primary ml-10"
                            onClick={() => this.setState({ openImportDialog: true })}
                        >
                            {<span><i className="icon icon-upload" />Import From Excel </span>}</button>
                    </span>}
                    exportExcel={true}
                    Reportdata={excelData}
                    excelFileName={fileName}
                >
                </ObseleteEuiTable>
            </EuiTile>
            <DialogModal
                id="additem"
                open={this.state.openAddDialog}
                title="Add Temaplate Item"
                content={<AddChecklistItem />}
                buttonName="Add"
                type="primary"
                handleClose={() => { this.setState({ openAddDialog: false }) }}
                onClick={this.addEditItem}
                contentStyle={{ width: "600px" }}

            />
            <DialogModal
                id="editItem"
                open={this.state.openEditDialog}
                title="Edit Temaplate Item"
                content={<AddChecklistItem />}
                buttonName="Save"
                type="primary"
                handleClose={() => { this.setState({ openEditDialog: false }) }}
                onClick={this.addEditItem}
                contentStyle={{ width: "600px" }}

            />
            <DialogModal
                id="deleteItem"
                open={this.state.openDeleteDialog}
                title="Delete Template Item"
                content={
                    <div>Are you sure you want to Delete this item?</div>}
                buttonName="Delete"
                type="primary"
                handleClose={() => { this.setState({ openDeleteDialog: false }) }}
                onClick={this.deleteItem}
                mainButtonStyle={{ backgroundColor: 'red' }}
            />
            <DialogModal
                id="importData"
                open={this.state.openImportDialog}
                title="Import from excel"
                content={
                    <div className="w-10">
                        <div style={{ color: "red" }} >*Use only pre-defined download template to upload items </div>
                        <input style={{ width: "100%" }} className="w-10" type="file" onChange={(e) => { this.handleFile(e) }} id="myFileInput" accept=".csv, .xlsx, .xls" />
                    </div>}
                buttonName="Import"
                type="primary"
                handleClose={() => { this.setState({ openImportDialog: false }) }}
                onClick={this.importExcel}
                contentStyle={{ width: "400px" }}
            />
            <DialogModal
                id="downloadOptions"
                open={this.state.openOptionsDialog}
                title="Download Template"
                content={
                    <div className="w-10">
                        <input id="createTemplate" name="group" type="radio" value="createTemplate"
                            checked={this.state.selectedOption === "createTemplate"}
                            onChange={this.handleChange}
                        />
                        <label for="createTemplate" >Create New</label>


                        <input id="editTemplate" name="group" type="radio" value="editTemplate"
                            checked={this.state.selectedOption === "editTemplate"}
                            onChange={this.handleChange}
                        //   disabled={this.state.sheetdisable}
                        />
                        <label for="editTemplate" >Edit Existing Data</label>
                    </div>}
                buttonName="Download"
                type="primary"
                handleClose={() => { this.setState({ openOptionsDialog: false }) }}
                onClick={this.downloadTemplate}
                contentStyle={{ width: "400px" }}
            />
            <DialogModal
                id="bulkUpdate"
                open={this.state.openEditImportDialog}
                title="Bulk Update"
                disableBackdropClick={true}
                content={
                    <div className="w-10">
                        <div style={{ color: "red" }} >*Download edit template to edit and upload </div>
                        <FormField className="w-10 mt-2 mb-2">
                            <EuiCheckbox
                                label="Skip Null Values"
                                className="flex-start-content pl-0 pr-1"
                                id="skipNulls"
                                refValue={this.state.skipNulls}
                                onChange={(e) => this.setState({ skipNulls: e.target.value })}
                                defaultChecked={false}
                                checked={this.state.skipNulls}
                                name="skipNulls" />
                        </FormField>
                        <input style={{ width: "100%" }} className="w-10" type="file" onChange={(e) => { this.handleFile(e) }} id="myFileInput" accept=".csv, .xlsx, .xls" />
                    </div>}
                buttonName="Update"
                type="primary"
                handleClose={() => { this.setState({ openEditImportDialog: false, skipNulls: false }) }}
                onClick={this.importEditExcel}
                contentStyle={{ width: "400px" }}
            />

        </EuiRow >
        );
    }
}


const mapStateToProps = state => ({ userData: state.user, aspResourcesData: state.asp, navbarData: state.navigation, activityTemplateData: state.activityChecklists });
const mapDispatchToProps = dispatch => {
    return {
        changeCheckListMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setSelectedTemplate: (selectedTemplate) => dispatch({ type: "SET_SELECTED_TEMPLATE", payload: selectedTemplate }),
        setItem: (item) => dispatch({ type: "SET_ITEM", payload: item }),
        setTemplates: (temps) => dispatch({ type: "SET_TEMPLATES", payload: temps }),
        setData: (data) => dispatch({ type: "SET_DATA", payload: data }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivityChecklistItemsSubList));