import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import EuiWizard from "../../../eui-components/wizard";
import EuiRow from "../../../eui-components/row";
import EuiTile from "../../../eui-components/tile";
import FirstStepContent from "./firstStepContent";
import SecondStepContent from "./secondStepContent";
import ThirdStepContent from "./thirdStepContent";
import FourthStepContent from "./fourthStepContent";
import FifthStepContent from "./fifthStepContent";
import ApiClient from "../../../utils/api-client";
import PageHelper from "../../../utils/page-helper";
import { ToastContainer, toast } from 'react-toastify';
import { clone, cloneDeep, omit } from "lodash";
import SixthStepContent from "./sixthStepContent";
import constants from "../../../utils/constants";
import CommonHelper from "../../../utils/common-helper";
import SasHelper from "../../../utils/sas-helper";

class CreateSession extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            currentStep: 1,
            siteId: 0
        };
    }
    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }

    onNextClick = (event) => {
        
        this.setState({ currentStep: this.state.currentStep + 1 }, () => {
            if (this.state.currentStep == 2) {
                let newObj = { ...this.props.sessionData.sessionInfo };
                delete newObj["customerScopeIdentification"];
                delete newObj["updateDate"];
                delete newObj["updateUser"];
                delete newObj["createDate"];
                delete newObj["createUser"];
                delete newObj["submitDate"];
                delete newObj["submitUser"];
                delete newObj["submitUserId"];
                delete newObj["approveDate"];
                delete newObj["approveUser"];
                delete newObj["rejectDate"];
                delete newObj["rejectUser"];
                delete newObj["comments"];
                delete newObj["rating"];
                delete newObj["createUserRole"];
                delete newObj["readySubmitDate"];
                delete newObj["readySubmitUser"];
                delete newObj["workflowId"];
                delete newObj["workflow"];
                delete newObj["isLiveStream"];
                delete newObj["liveStreamStartDate"];
                delete newObj["liveStreamStartTime"];
                delete newObj["liveStreamEndDate"];
                delete newObj["liveStreamEndTime"];
                delete newObj["projectId"];
                delete newObj["projectName"];
                delete newObj["updateUserName"];
                delete newObj["submitUserName"];
                delete newObj["rejectUserName"];
                delete newObj["approveUserName"];
                delete newObj["name"];
                delete newObj["ecaStatus"];
                delete newObj["ecaComment"];
                delete newObj["ecaLastModifiedDate"];
                delete newObj["ecaLastModifiedEmail"];

                

                if (!PageHelper.checkProperties(newObj)) {
                    toast.warn("Please, fill required fields!", { toastId: "requiredField", containerId: 'F', position: toast.POSITION.TOP_CENTER });
                    return;
                }
                if (this.props.sessionData.sessionInfo.siteId == this.state.siteId) {
                    return;
                }
                this.setSecondStepData(event);

            } if (this.state.currentStep == 3) {
                
                if (this.props.sessionData.sessionActivities.length > 0) {
                    
                    this.setThirdStepData();
                } else {
                    toast.warn("Please, select some activities!", { toastId: "selectActivity", containerId: 'F', position: toast.POSITION.TOP_CENTER });
                    this.props.setSessionSubActivities([]);
                    return;
                }
            } else if (this.state.currentStep == 4) {
                
                const count =
                    this.props.sessionData.sessionSubActivities.length > 0 ? this.props.sessionData.sessionSubActivities
                        .map(item => item.subActivities).reduce((a, b) => { return a.concat(b); })
                        .filter(subItem => ('tableData' in subItem)
                            && subItem.tableData
                            && ('checked' in subItem.tableData)
                            && subItem.tableData.checked == true).length
                        :
                        0;
                if (count > 0) {
                    const cleanArray = this.props.sessionData.sessionSubActivities.map(item => {
                        if (item.subActivities) {
                            item.subActivities = item.subActivities.map(subItem =>
                                (omit(subItem, "tableRef")));
                        }
                        return omit(item, "tableRef");
                    });

                    const selectedSubActivities = cloneDeep(cleanArray);
                    this.props.setSessionDocuments(selectedSubActivities);
                } else {
                    toast.warn("Please, select some checklist items!", { toastId: "selectSubActivity", containerId: 'F', position: toast.POSITION.TOP_CENTER });
                    this.props.setSessionDocuments([]);
                    return;
                }
            } else if (this.state.currentStep == 6) {
                const cleanArray = this.props.sessionData.sessionDocuments.map(item => {
                    if (item.subActivities) {
                        item.subActivities = item.subActivities.map(subItem =>
                            (omit(subItem, "tableRef")));
                    }
                    return omit(item, "tableRef");
                });
                
                

                let sessionSummary = cloneDeep(cleanArray);
                sessionSummary.map(item => {
                    if (item.subActivities && item.subActivities.length > 0) {
                        item.subActivities =
                            [...item.subActivities.filter(sub => (
                                ('tableData' in sub)
                                && sub.tableData
                                && ('checked' in sub.tableData)
                                && sub.tableData.checked == true)
                                || sub.sessionAcceptance === 1)
                            ];
                        item.subActivities.map(subItem => {
                            //if while editing one of sub activity unselect.
                            if (('tableData' in subItem) &&
                                subItem.tableData &&
                                'checked' in subItem.tableData &&
                                subItem.tableData.checked == false &&
                                subItem.sessionAcceptance == 1) {
                                subItem.sessionAcceptance = 2;
                            }
                            subItem.tableData = null;
                        });
                    }
                    item.tableData = null;
                });
                this.props.setSessionSummary(sessionSummary);

                
                
                

            } else if (this.state.currentStep == 7) {
                this.finishSession();
            }
        });
    }

    onPreviousClick = (event) => {
        
        if (this.state.currentStep > 1) {
            this.setState({ currentStep: this.state.currentStep - 1 },
                () => {
                })
        }
    }

    setSecondStepData = () => {
        
        this.setState({ siteId: this.props.sessionData.sessionInfo.siteId, isLoading: true }, () =>
            ApiClient.post("Session/GetActivitiesOfSite",
                { siteId: this.props.sessionData.sessionInfo.siteId, sessionId: this.props.sessionData.sessionInfo.id })
                .then(response => {
                    let allActivity = response.data;
                    
                    if (allActivity.length > 0 && this.props.sessionData.sessionInfo.id > 0) {
                        allActivity[0]["tableData"] = {};
                        allActivity.map(item => {
                            if (item.sessionId > 0) {
                                item.tableData = { id: item.activityId, checked: true };
                            }
                        })
                    }
                    this.setState({ isLoading: false },
                        () => {
                            this.props.setSessionAllActivities(allActivity.filter(item => item.rscScope !== constants.rscScopeName));
                            this.props.setSessionRemoteActivities(allActivity.filter(item => item.rscScope === constants.rscScopeName && item.rscStatus === 1));
                            this.props.setSessionActivities(allActivity.filter(item =>
                                item.rscScope !== constants.rscScopeName &&
                                (item.hasOwnProperty("tableData") &&
                                    item.tableData.hasOwnProperty("checked") &&
                                    item.tableData.checked == true)
                            ));
                        });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                })
        );
    }

    setThirdStepData = () => {
        
        let activityList = [...this.props.sessionData.sessionActivities.filter(item => (item.sessionType == 6) ||
            item.hasOwnProperty("tableData")
            && item.tableData
            && item.tableData.hasOwnProperty("checked")
            && item.tableData.checked == true)];
        
        
        if (activityList.length == 0) {
            return;
        }
        this.setState({ isLoading: true }, () => {
            activityList[0]["subActivities"] = "SubActivities";
            activityList.map(item => {
                item.subActivities = [];
            });
            ApiClient.post("Session/GetSubItemsOfActivity", activityList)
                .then(response => {
                    let newActivityList = response.data;
                    const recordings = newActivityList.map(item => item.recordings ?? []).reduce((a, b) => a.concat(b));
                    PageHelper.mapActivityData(newActivityList, this.props.sessionData.sessionInfo.id);
                    this.setState({ isLoading: false },
                        () => {
                            this.props.setSessionSubActivities(newActivityList);
                            this.props.setRecordings(PageHelper.mapRecordings(recordings));
                        }
                    )
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                })
        });
    }

    createSession = (type) => {
        let activityList = [...this.props.sessionData.sessionSummary];
        if (activityList.length === 0) {
            toast.warn("Please, select at least one activity to create session!", { containerId: 'F', position: toast.POSITION.BOTTOM_RIGHT });
            return;
        }

        activityList.map(item => {
            delete item.tableRef;
            delete item.tableData;

            if (item.subActivities && item.subActivities.length > 0) {
                if (type == 1 && this.props.sessionData.sessionSummary.length == 0) {
                    item.subActivities = item.subActivities
                        .filter(subItem =>
                            subItem.hasOwnProperty("tableData")
                            && subItem.tableData
                            && subItem.tableData.hasOwnProperty("checked")
                            && subItem.tableData.checked == true);
                }
                item.subActivities.map(subItem => {
                    delete subItem.documents;
                    delete subItem.tableData;
                    delete subItem.viewerIsOpen;
                    delete subItem.currentImage;
                })
            }
        });

        const remoteActivityList = [...this.props.sessionData.sessionRemoteActivities.filter(item => item.hasOwnProperty("tableData") &&
            item.tableData.hasOwnProperty("checked") &&
            item.tableData.checked == true)];

        const session = { ...this.props.sessionData.sessionInfo };
        session.customerId = session.customerId.value;
        session.workflowId = session.workflowId.id === 0 ? null : session.workflowId.id;
        session.type = session.type ? constants.customerSessionTypes.ECA : constants.customerSessionTypes.ERITOP;
        session.ecaType = session.type != constants.customerSessionTypes.ECA ? null :
            (session.ecaType ? constants.ecaSessionType.AUTO : constants.ecaSessionType.MANUAL);

        
        const sessionActivityIds = [...this.props.sessionData.sessionActivityIds];

        if (!session.customerId || session.customerId == 0) {
            return;
        }

        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/CreateSession", { session, activityList, remoteActivityList, sessionActivityIds, type })
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false }, () => this.props.history.push("/my-customer-sessions"));
                    } else {
                        this.setState({ isLoading: false });
                        CommonHelper.createNotification({ title: "Error", description: "Your selected activities already used in another session, Please create another session!", icon: "icon-cross" });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        })
    }

    editSession = (type) => {
        let activityList = [...this.props.sessionData.sessionSummary];
        if (activityList.length === 0) {
            toast.warn("Please, select at least one activity to edit session!", { toastId: "activityControl", containerId: 'F', position: toast.POSITION.BOTTOM_RIGHT });
            return;
        }

        activityList.map(item => {
            delete item.tableRef;
            delete item.tableData;

            if (item.subActivities && item.subActivities.length > 0) {
                if (type == 1 && this.props.sessionData.sessionSummary.length == 0) {
                    item.subActivities = item.subActivities
                        .filter(subItem =>
                            subItem.hasOwnProperty("tableData")
                            && subItem.tableData
                            && subItem.tableData.hasOwnProperty("checked")
                            && subItem.tableData.checked == true);
                }
                item.subActivities.map(subItem => {
                    delete subItem.documents;
                    delete subItem.tableData;
                    delete subItem.viewerIsOpen;
                    delete subItem.currentImage;
                })
            }
        });

        let remoteActivityList = [...this.props.sessionData.sessionRemoteActivities.filter(item => item.hasOwnProperty("tableData") &&
            item.tableData.hasOwnProperty("checked"))];

        if (remoteActivityList.length > 0) {
            remoteActivityList.map(item => {
                if (item.tableData.checked === false) {
                    item.sessionType = 6; // If unselected, this RCA Activity's sessionId will update null.
                }
            })
        }

        const session = { ...this.props.sessionData.sessionInfo };
        session.customerId = session.customerId.value;
        session.workflowId = session.workflowId.id === 0 ? null : session.workflowId.id;
        session.type = session.type ? constants.customerSessionTypes.ECA : constants.customerSessionTypes.ERITOP;
        session.ecaType = session.type != constants.customerSessionTypes.ECA ? null :
            (session.ecaType ? constants.ecaSessionType.AUTO : constants.ecaSessionType.MANUAL);

        

        if (!session.customerId || session.customerId == 0) {
            return;
        }
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/EditSession", { session, activityList, remoteActivityList })
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false }, () => this.props.history.push("/my-customer-sessions"));
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        })
    }

    finishSession = () => {
        if (this.props.sessionData.sessionInfo.viewOnly) {
            this.props.history.push("/");
            return;
        }
        if (this.props.sessionData.sessionInfo.id == 0) {
            this.createSession(2);
        } else {
            this.editSession(2);
        }
        
        
    }

    cancelSession = () => {
        this.props.history.push("/my-customer-sessions");
    }

    saveSession = () => {
        this.createSession(1);
        
        
    }

    render() {
        return (
            <EuiRow>
                {
                    <EuiTile fullscreen tileStyle={{ padding: 10 }} styleContent={{ height: 630 }}>
                        <EuiWizard id="sessionWizard"
                            steps={[
                                { description: "1.Select Customer and Project Site", current: "current", completed: "", visible: "visible" },
                                { description: "2.Select Activities", current: "", completed: "", visible: "visible" },
                                { description: "3.Select Acceptance Documents", current: "", completed: "", visible: "visible" },
                                { description: "4.Create Session Documents", current: "", completed: "", visible: "visible" },
                                { description: "5.Session Document Library", current: "", completed: "", visible: "hidden" },
                                { description: "6.Review and Complete", current: "", completed: "", visible: "hidden" }
                            ]}
                            contents={[
                                {
                                    title: "Select Customer and Project Site", hidden: "", content: <div><FirstStepContent /></div>
                                },
                                {
                                    title: "Select Activities", hidden: "hidden", content: <div><SecondStepContent isLoading={this.state.isLoading} /></div>
                                },
                                {
                                    title: "Select Acceptance Documents", hidden: "hidden", content: <div><ThirdStepContent isLoading={this.state.isLoading} /></div>
                                },
                                {
                                    title: "Create Session Document", hidden: "hidden", content: <div><FifthStepContent /></div>
                                },
                                {
                                    title: "Session Document Library", hidden: "hidden", content: <div><FourthStepContent /></div>
                                },
                                {
                                    title: "Review and Complete", hidden: "hidden", content: <div><SixthStepContent /></div>
                                }
                            ]}
                            onNextClick={(event) => this.onNextClick(event)}
                            onPreviousClick={(event) => this.onPreviousClick(event)}
                            onCancelClick={this.cancelSession}
                            onSaveClick={this.saveSession}

                        >
                        </EuiWizard>
                        <ToastContainer enableMultiContainer containerId={'F'} />
                    </EuiTile>
                }
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, navbarData: state.navigation, userData: state.user, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setSessionInfo: sessionInfo => dispatch({ type: "SET_SESSION_INFO", payload: sessionInfo }),
        setSessionAllActivities: (activities) => dispatch({ type: "SET_SESSION_ALL_ACTIVITIES", payload: activities }),
        setSessionActivities: (activities) => dispatch({ type: "SET_SESSION_ACTIVITIES", payload: activities }),
        setSessionSubActivities: (subActivities) => dispatch({ type: "SET_SESSION_SUB_ACTIVITIES", payload: subActivities }),
        setSessionDocuments: (documents) => dispatch({ type: "SET_SESSION_DOCUMENTS", payload: documents }),
        setSessionSummary: (summary) => dispatch({ type: "SET_SESSION_SUMMARY", payload: summary }),
        setSessionRemoteActivities: (activities) => dispatch({ type: "SET_SESSION_REMOTE_ACTIVITIES", payload: activities }),
        setRecordings: (recordings) => dispatch({ type: "SET_RECORDINGS", payload: recordings }),
        setAllUsers: (users) => dispatch({ type: "SET_ALL_USERS", payload: users }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(CreateSession)));
