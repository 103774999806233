import React from "react";
import PageHelper from "../utils/page-helper";
import Table from "./table";
import SasHelper from "../utils/sas-helper";


export default class GroupedDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupedDocumentColumns: [
                {
                    title: "", field: "type",
                    render: rowData => PageHelper.renderDocumentType(rowData, this.props.documents),
                    cellStyle: {display:"flex", padding: 0 , width:200},
                    defaultGroupOrder: 0
                },
                {
                    title: "Name", field: "name", grouping: false,
                    cellStyle: { width: "100%", padding: 0 },
                    render: rowData => <a href={SasHelper.addSasKey(rowData.documentPath)} target="_blank">{rowData.name}</a>
                },                
            ],
            tableRef: React.createRef()
        }
    }
    componentDidMount() {

    }

    render() {
        return (
            <Table
                title={this.props.title}
                titleStyle={{ paddingBottom: 10 }}
                columns={this.state.groupedDocumentColumns}
                data={this.props.documents}
                groupbar={{ custom: <div></div> }}
                groupRow={{ custom: true }}
                options={{
                    paging: false,
                    minBodyHeight: 390,                    
                    selection: true,
                    grouping: true,
                    showSelectAllCheckbox: false,
                    header: false,
                    showTextRowsSelected: false                  
                }}    
                tableRef={this.state.tableRef}
                actions={[                   
                    {
                        tooltip: 'Download All Selected Documents',
                        icon: () => <i className="icon icon-download-save"></i>,
                        onClick: (evt, data) => PageHelper.downloadFiles(data, { tableRef: this.state.tableRef })
                    },
                    ...(this.props.actions || [])
                ]}
            >
            </Table>
        );
    }
}

