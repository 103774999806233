import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import PageHelper from "../../../utils/page-helper";
import { ToastContainer, toast } from 'react-toastify';
import SimpleExpansionPanel from "../../../components/expansionpanel";
import AdvancedGridList from "../../../components/advancedgridlist";
import Table from "../../../components/table";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EuiFooter from "../../../layout/footer";
import constants from "../../../utils/constants";
import DialogModal from "../../../components/dialog";
import SimpleSlider from "../../../components/slider";
import EuiSelect from "../../../eui-components/select";
import SummaryDocuments from "../../sessions/approve-session/summaryDocuments";
import ApproveSessionDialog from "../../sessions/approve-session/approveSessionDialog";
import ApiClient from "../../../utils/api-client";
import RateActivity from "./rateActivity";
import EuiAccordion from "../../../eui-components/accordion";
import ActivityInboxHelper from "../../../utils/activity-inbox-helper";
import EuiSwitch from "../../../eui-components/switch";
import SeverityLevelDialog from "../../sessions/approve-session/severityLevelDialog";
import EuiTextField from "../../../eui-components/textfield";
import DateUtils from "../../../utils/date-utils";
import SubactivityDuration from "./subactivityDuration";
import EuiLoading from "../../../eui-components/loading";
import CommonHelper from "../../../utils/common-helper";
import { isEmpty, trim, upperCase } from "lodash";

class ApproveActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            subActivitiesColumns:
                [
                    {
                        title: "", field: "name",
                        render: (rowData) => this.renderSubActivityDocuments(rowData),
                        cellStyle: { padding: 0, fontSize: "0.875rem" }
                    },
                ],
            subActivityDocumentsColumns: [
                {
                    title: "Name", field: "name", render: (rowData) => this.renderName(rowData),
                    cellStyle: { padding: 3, fontSize: "0.8rem", width: "25%" }
                },
                {
                    title: "Status", field: "acceptanceStatusId",
                    render: (rowData) => {
                        return this.renderStatus(rowData)
                    },
                    cellStyle: { padding: 3, width: "10%" }
                },
                {
                    title: "Severity", field: "rscSeverityLevel", render: (rowData) => PageHelper.renderSeverityLevel(rowData.rscSeverityLevel),
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                    editable: "never"
                },
                {
                    title: "Site ASP Comments", field: "siteAspComments",
                    cellStyle: { padding: 3, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "30%" }
                },
                {
                    title: "RSC Engineer Comments", field: "reviewComments",
                    cellStyle: { padding: 3, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "30%" }
                },
                {
                    title: "RSC Engineer Name", field: "rscPerson", render: (rowData) => rowData.rscPerson?.name,
                    cellStyle: { padding: 3, fontSize: "0.8rem", width: "5%" }
                },
            ],
            statusList: constants.statusList.filter(item => item.value !== 4),
            approveExcludeSwitchRef: false,
            viewMod: 3,
            tableRef: React.createRef(),
            selectedRow: { id: 0 },
            selectedDocument: { id: 0 },
            expandAllDetailPanel: false,

            openDialog: false,
            openDialogPreview: false,
            openDialogRating: false,
            openDialogDelete: false,
            openDialogInfo: false,
            openDialogMainIssue: false,

            reviewComments: "",
            status: constants.acceptanceStatusTypes.REJECTED,

            allDocument: [],
            imgIndex: 0,
            imgUrlLength: 0,
            totalCount: "",

            sessionComment: "",

            approvalType: 0,

            showCompleted: false,
            showCommentAvailable : false,
            showVIAccepted: false,
            showRejected: false,
            showVIRejected : false,
            rscNotReviewed : false,
            filter: true,
            selectedDeleteDocuments: [],
            selectedSubActivity: { id: 0 },

            rejectReason: 5,

            allAcceptOrReject: 0,
            openAllDialog: false
        }
    }

    componentDidMount() {

        console.log("Activity" + JSON.stringify(this.props.activityInboxData.currentActivity?.activityRatingDate))
        if (this.props.activityInboxData.currentActivity &&
            this.props.activityInboxData.currentActivity.subActivities &&
            this.props.activityInboxData.currentActivity.subActivities.map(item => item.documents && item.documents.length > 0).length > 0) {
            const allDocument = this.props.activityInboxData.currentActivity
                .subActivities.map(item => item.documents)
                .reduce((a, b) => { return a.concat(b); });
            this.setState({ allDocument, imgUrlLength: allDocument.length });
        }
    }

    renderSubActivityDocuments = (rowData) => {
        return <SimpleExpansionPanel
            key={rowData.id + "_subActivity"}
            id={"approveSubactivityDocumentsAccordion" + rowData.id}
            title={this.getSubActivityName(rowData)}
            opened={rowData.opened}
            onChange={() => this.togglePanel(rowData)}
            content={<EuiRow>
                {this.setView(rowData)}
            </EuiRow>
            }
            style={{ backgroundColor: "#f2f2f2" }}
        />
    }

    renderStatus = (rowData, preview = "") => {
        const color = PageHelper.renderActivityStatusColor(rowData.acceptanceStatusId);
        return <div key={rowData.id + "_status"} className="flex-start-content w-10">
            <i className={"icon icon-alarm-level4 color-" + color} style={{ width: "14%" }}> </i>
            <EuiSelect
                style={{
                    background: "inherit", border: "none",
                    color: (this.state.openDialogPreview || this.props.navbarData.theme === "dark" ? "#fff" : "#242424"),
                    fontSize: (this.state.openDialogPreview ? 17 : "0.8rem")
                }}
                optionListStyle={{ position: "inherit" }}
                options={this.state.statusList}
                value={rowData.acceptanceStatusId || 1}
                name={"status" + rowData.id}
                id={"status" + rowData.id + preview}
                onChange={e => this.handleChange(e)}
                disabled={this.props.activityInboxData.currentActivity.viewOnly}
            />
        </div>
    }

    renderFilePreview = () => {
        const allImages = [...this.state.allDocument];
        const comments = this.state.selectedDocument.reviewComments ?? "";
        const severityLevel = PageHelper.renderSeverityLevel(this.state.selectedDocument.rscSeverityLevel)
        return <SimpleSlider
            id="activityInboxSlider"
            slideData={allImages}
            currentSlide={this.state.selectedDocument}
            initialSlide={this.state.imgIndex}
            totalCount={this.state.totalCount}
            onNext={this.nextClick}
            onPrev={this.prevClick}
            onClose={this.toogleModal}
            status={this.renderStatus(this.state.selectedDocument, "preview")}
            comment={comments}
            severityLevel={severityLevel}
            setImageTrack={(slide) => this.setImageTrack(slide)}
            commentTitle="RSC Engineer Comments"
            siteComment={this.state.selectedDocument.siteAspComments}
            type={3}
            editImage={true}
            jobRole={this.props.userData.userData.jobRole}
        />
    }

    renderName = (rowData) => {
        const view = PageHelper.getDocumentType(rowData.type);
        let color = PageHelper.getDocumentTypeColor(rowData.type);
        if (color === "#242424" && this.props.navbarData.theme === "dark") {
            color = "#fff";
        }
        return <a key={rowData.id + "_name"} onClick={() => this.selectAndOpenDocument(rowData)} style={{ display: "flex", color }}>{view}<span style={{ paddingTop: 3 }}>{rowData.name}</span></a>;
    }

    selectAndOpenDocument = (rowData) => {
        if (!rowData.documentPath) {
            toast.error("Can not open document before saving!", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
            return;
        }
        this.setImageTrack(rowData);
    }

    nextClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === prevState.imgUrlLength - 1 ? 0 : prevState.imgIndex + 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument });
        });
    }

    prevClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === 0 ? prevState.imgUrlLength - 1 : prevState.imgIndex - 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument })
        });
    }

    setImageTrack = (slide) => {
        const allImages = [...this.state.allDocument];
        const imgUrlLength = allImages.length;
        let imgIndex = allImages.findIndex(item => item.id === slide.id);
        this.setState({ selectedDocument: slide, imgIndex, imgUrlLength, totalCount: (imgIndex + 1) + "/" + imgUrlLength }, () => { this.setState({ openDialogPreview: true }); });
    }

    toogleModal = () => {
        this.setState({ openDialogPreview: false });
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        if (value === constants.acceptanceStatusTypes.ACCEPTED || value === constants.acceptanceStatusTypes.REJECTED) {
            this.handleDialog(value);
        } else if (value === constants.acceptanceStatusTypes.PENDING) {
            this.handlePending();
        }
    }

    handleDialog = (value) => {
        const reviewComments = this.state.selectedDocument.reviewComments;
        const rejectReason = this.state.selectedDocument.rscSeverityLevel || 5;
        this.setState({
            openDialog: true,
            reviewComments,
            status: value,
            rejectReason: value === constants.acceptanceStatusTypes.REJECTED ? rejectReason : null,
        });
    }

    handlePending = () => {
        this.setState({ reviewComments: "", status: 1, rejectReason: 0 }, () => { this.changeStatus(); });
    }

    changeStatus = () => {
        const activity = { ...this.props.activityInboxData.currentActivity };
        const status = this.state.status;
        const document = { ...this.state.selectedDocument };
        document.reviewComments = this.state.reviewComments;
        document.acceptanceStatusId = status;
        document.reviewDate = new Date();
        document.rscPersonId = this.props.userData.rscPerson.id;
        document.rscPerson = this.props.userData.rscPerson;
        document.rscSeverityLevel = this.state.rejectReason;

        const subActivities = [...activity.subActivities];
        const i = subActivities.findIndex(item => item.id == document.subactivityId);
        const documents = subActivities[i] && [...subActivities[i].documents];
        documents[documents.findIndex(item => item.id === document.id)] = document;
        subActivities[i].documents = documents;
        activity.subActivities = subActivities;

        this.props.setCurrentActivity(activity);

        let allDocument = [...this.state.allDocument];
        allDocument[allDocument.findIndex(item => item.id === document.id)] = document;

        this.setState({ allDocument, selectedDocument: document });
    }

    changeAllStatus = (status) => {
        const activity = { ...this.props.activityInboxData.currentActivity };

        if (activity.subActivities && activity.subActivities.length > 0) {
            activity.subActivities.map(subItem => {
                if (subItem.documents && subItem.documents.length > 0) {
                    subItem.documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                        document.acceptanceStatusId = status;
                        document.reviewDate = new Date();
                        document.rscPersonId = this.props.userData.rscPerson.id;
                        document.rscPerson = this.props.userData.rscPerson;
                        document.rscSeverityLevel = status === constants.acceptanceStatusTypes.REJECTED ? this.state.rejectReason : null;
                    });
                }
            });
        }

        let allDocument = [...this.state.allDocument];
        if (allDocument.length > 0) {
            allDocument.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                document.acceptanceStatusId = status;
                document.rscPersonId = this.props.userData.rscPerson.id;
                document.rscPerson = this.props.userData.rscPerson;
                document.rscSeverityLevel = status === constants.acceptanceStatusTypes.REJECTED ? this.state.rejectReason : null;
            });
        }

        this.props.setCurrentActivity(activity);
        this.setState({ allDocument, openAllDialog: false });
    }

    getSubActivityName = (rowData) => {
        const color = ActivityInboxHelper.getSubActivityColor(rowData);
        const icon = color === "" ? "icon-circle" : "icon-alarm-level5 color-" + color;
        return <div style={{ flexDirection : 'column', display : 'flex' , alignItems : 'flex-start' , overflow : 'hidden'}}> <i className={"flex-middle-content text-sm icon " + icon}>
            <span className="subactivity-title">
                {rowData.name + " ( " + this.getSubActivitiesCount(rowData) + " ) "}
            </span>
            {rowData.completed ? <i className="icon icon-check text-lg color-green"></i> : <i className="icon icon-minus text-lg color-red"></i>}
        </i>
        {  rowData.comments && <i style={{marginBottom : 0}}> <span style={{color : "green"}}> Comment : </span>  {rowData.comments}</i> }
        </div>
    }

    renderRow = (rowData) => {
        var selected = (this.state.selectedDocument && this.state.selectedDocument.id === rowData.id);
        var background = 'silver';
        if (this.props.navbarData.theme === "dark") {
            background = "#353535";
        }
        return {
            background: selected ? background : 'inherit',
        }
    }

    setView = (rowData) => {
        if (this.state.viewMod === 1) {
            return <>
                <SubactivityDuration rowData={rowData} />
                <AdvancedGridList
                    tileData={this.getDocuments(rowData)}
                    selectedDocument={(tile) => this.setState({ selectedDocument: tile })}
                    onClickApprove={() => this.handleDialog(2)}
                    onClickReject={() => this.handleDialog(3)}
                    onClickPending={() => this.handlePending()}
                    viewOnly={this.props.activityInboxData.currentActivity.viewOnly}
                    onClickImage={(tile) => { this.selectAndOpenDocument(tile) }}
                    type={3}
                />
            </>
        } else {
            return <Table
                key={rowData.id + "obseleteTable"}
                columns={this.state.subActivityDocumentsColumns}
                container={{ style: { background: "inherit" } }}
                data={this.getDocumentsByMod(rowData)}
                onRowClick={(event, rowData) => this.setState({ selectedDocument: rowData })}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    paging: false,
                    minBodyHeight: 50,
                    showTextRowsSelected: false,
                    selection: true,
                    headerStyle: {
                        padding: 2, fontSize: "0.875rem", background: 'transparent',
                        color: "inherit",
                        whiteSpace: "nowrap",
                        fontWeight: 500,
                    },
                    rowStyle: (rowData) => this.renderRow(rowData),
                }}
                actions={[
                    {
                        tooltip: 'Download All Selected Documents',
                        icon: () => <i className="icon icon-download-save"></i>,
                        onClick: (evt, data) => PageHelper.downloadFiles(data, rowData)
                    },
                    {
                        tooltip: 'Delete All Selected Documents',
                        icon: () => <i className="icon icon-trashcan"></i>,
                        onClick: (evt, data) => this.setState({ selectedDeleteDocuments: data, selectedSubActivity: rowData, openDialogDelete: true }),
                        hidden: this.props.activityInboxData.currentActivity.viewOnly
                    }
                ]}
                tableRef={rowData.tableRef}
                title={<SubactivityDuration rowData={rowData} />}
            >
            </Table>
        }

    }

    getDocumentsByMod = (rowData) => {
        const mod = this.state.viewMod;
        return this.getDocuments(rowData, mod === 2)
    }

    togglePanel = (rowData) => {
        this.setState({ selectedRow: rowData }, () => {
            let activity = { ...this.props.activityInboxData.currentActivity }
            if (activity.id > 0) {
                activity.subActivities.map(subItem => {
                    if (subItem.id == rowData.id) {
                        subItem.opened = subItem.opened == "opened" ? "" : "opened";
                    } else {
                        subItem.opened = "";
                    }
                })
            }
            this.props.setCurrentActivity(activity);
        });
    }

    toogleAllDetailPanel = () => {
        this.setState({ expandAllDetailPanel: !this.state.expandAllDetailPanel }, () => {
            const activity = { ...this.props.activityInboxData.currentActivity };

            if (activity.subActivities) {
                activity.subActivities.map(subItem => {
                    if (this.state.expandAllDetailPanel === true) {
                        subItem.opened = "opened";
                    } else {
                        subItem.opened = "";
                    }
                });
            }
            this.props.setCurrentActivity(activity);
        });
    }

    totalStatus = (type) => {
        return this.state.allDocument.filter(item => item.acceptanceStatusId === type).length;
    }

    updateActivity = (activity, type) => {
        if (type === constants.activityInboxApprovalTypes.APPROVE) {
            activity.acceptanceStatusId = constants.acceptanceStatusTypes.ACCEPTED;
            activity.completed = 1;
            activity.actualEnd = DateUtils.getUtcDate();
            activity.ratingDate = DateUtils.getUtcDate();
            activity.activityRatings = this.props.activityInboxData.ratings;
            activity.viewOnly = true;
            activity.showRating = true
        } else if (type === constants.activityInboxApprovalTypes.REJECT) {
            activity.acceptanceStatusId = constants.acceptanceStatusTypes.REJECTED;
            activity.ratingDate = DateUtils.getUtcDate();
            activity.activityRatings = this.props.activityInboxData.ratings;
            activity.viewOnly = true;
            activity.showRating = true
        }
    }
    checkPendingItems = (type) => {
        if (this.state.allDocument.some(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING)) {
            toast.warn(constants.processWarnMessage, { containerId: 'AA', position: toast.POSITION.BOTTOM_RIGHT })
            return;
        } else if (this.props.activityInboxData.currentActivity.completedMaxDate) {
            toast.error("Due to activity hasn't been sent to rsc, can't approve or reject!", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
            return;
        } else {
            this.setState({ approvalType: type, openDialogRating: true });
        }
    }
    checkIsValidApproveRating = () => {
        // Technical Rating
        const TechnicalRating = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question)) ===upperCase(trim("Technical Rating"))  
        );
    
        // Behavior Rating
        const BehaviorRating = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question))  === upperCase(trim("Behavior Rating")) 
        );
        // QI if Done
        const QIifDone = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question))  === upperCase(trim("QI if Done")) 
        );
        // FTR Status
        const FTRStatus = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question))  ===upperCase(trim("FTR Status")) 
        );
    
        // Main Issue
        const MainIssue = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question))  === upperCase(trim("Main Issue")) 
        ) || null;
    
        // RSC Acceptance Feedback
        const RSCAcceptanceFeedback = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question))  ===upperCase(trim("RSC Acceptance Feedback"))  
        );
    
        // Snag List
        const SnagList = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question))  ===upperCase(trim("Snag List"))  
        );
    
        // Pre-PAT FTR
        const PrePATFTR = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim( item.question))  ===upperCase(trim("Pre-PAT FTR"))  
        );

        // Pre-PAT By RSC
        const PrePATByRSC = this.props.activityInboxData?.ratings?.find(
          (item) => upperCase(trim(item.question))  ===upperCase(trim("Pre-PAT By RSC"))  
        );
        if (upperCase(trim(FTRStatus?.ratingValue)) ===upperCase(trim("FTR")) ) {
          MainIssue.ratingValue = "";
        }
    
        if (
          isEmpty(TechnicalRating?.ratingValue) ||
          isEmpty(BehaviorRating?.ratingValue) ||
          (isEmpty(QIifDone?.ratingValue) && !QIifDone?.isDisabled) ||
          isEmpty(FTRStatus?.ratingValue) ||
          (isEmpty(MainIssue?.ratingValue) && upperCase(trim(FTRStatus?.ratingValue)) !==upperCase(trim("FTR"))) ||
          isEmpty(RSCAcceptanceFeedback?.ratingValue) ||
          isEmpty(SnagList?.ratingValue) ||
          isEmpty(PrePATFTR?.ratingValue) ||
          isEmpty(PrePATByRSC?.ratingValue)
        ) {
          return true;
        } else {
          return false;
        }
      };
    saveActivityApproval = (typeButton) => {
        if (this.state.isLoading) {
            toast.error("Transaction is processing. Please wait!", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
            return;
        }
        if(typeButton==="ratingActivityDialog"){
            if (this.checkIsValidApproveRating()) {
                this.setState({ openDialogMainIssue: true });
                return;
              }
        }
     
        const type = this.state.approvalType;
        const activity = { ...this.props.activityInboxData.currentActivity.plainActivity };
        activity.activityDocuments = this.state.allDocument;
        activity.ratingComments = this.state.sessionComment;
        activity.logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        };
        // remove star from all Questions that is mandatory 
// this its because we add when we display in UI and now we have to remove it when we want to save the activity 
    // eslint-disable-next-line array-callback-return
    activity.activityRatings.map((item) =>{
        item.name = item.name.replace('*', '');
    })
        this.updateActivity(activity, type);
        this.setState({ isLoading: true, openDialogRating: false, openDialogInfo: type != constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER }, () => {
            ApiClient.post("ActivityInbox/SaveActivityApproval", activity, {}, 120000)
                .then(response => {
                    if (response.data) {
                        const mappedActivity = { ...this.props.activityInboxData.currentActivity };
                        mappedActivity.ratingComments = this.state.sessionComment;
                        this.updateActivity(mappedActivity, type);
                        this.props.setCurrentActivity(mappedActivity);
                        toast.success("Eritop Session Is updated Successfully", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                        toast.info("Updating Erisite Checklist Items", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                        // Update Erisite checklist items 
                        ApiClient.post("ActivityInbox/UpdateActivityChecklistItems", activity, {}, 120000).then(response => {
                            toast.success("Eritop Session and Erisite checklist items are updated successfully!", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                            this.setState({ isLoading: false, openDialogInfo: false });
                        }).catch(error => {
                            this.setState({ isLoading: false, openDialogInfo: false });
                            toast.error(constants.erisiteUpdateError, { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                            console.error(error);;
                        })
                    } else {
                        this.setState({ isLoading: false, openDialogInfo: false });
                        toast.error("An error occured while updating Eritop Session items!", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                        return;
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false, openDialogInfo: false });
                    toast.error(constants.erisiteUpdateError, { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                    console.error(error);;
                });
        });
    }

    getSubActivities = (rowDataActivity) => {
        if (rowDataActivity && rowDataActivity.subActivities && rowDataActivity.subActivities.length > 0) {
            return rowDataActivity.subActivities.filter(item => !this.isHidden(item));
                
        } else {
            return [];
        }
    }

    filterClearance = (item) => {
      var result =  this.state.rscNotReviewed && ( new Date(item.uploadDate) > new Date(this.props.activityInboxData.currentActivity?.activityRatingDate))
      return result
    }
    applyFilterOnDocument = (documents) => {
    var result =     documents?.filter(doc => 
            ((this.state.showRejected && doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED && doc.rscPerson?.name != "Eritop VI Agent" )) ||
            ((this.state.showVIAccepted && doc.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED && doc.rscPerson?.name === "Eritop VI Agent" ))  ||
            ((this.state.showVIRejected && doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED && doc.rscPerson?.name === "Eritop VI Agent" )) || this.filterClearance(doc))
    return result ?? [];
    /*var result =     documents?.filter(doc => 
        ((this.state.showRejected && doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED && doc.rscPerson?.name != "Eritop VI Agent" )))
return result ?? [];*/
    }
    applyCompletedFilter = (item)=>{
      const completed =  (this.state.showCompleted && item.completed)
      return completed
    }
    applyCommentFilter = (item) => {
        const comment =  (this.state.showCommentAvailable && item.comments)
        return comment   
    }
    applyFilter = (item) => {
    if (this.state.filter){
     return false
    }
    const completed = this.applyCompletedFilter(item)
    const comment = this.applyCommentFilter(item)
    const document =  this.applyFilterOnDocument(item.documents)
   // return  document?.length > 0  ?  !(completed || document?.length  > 0) : !(completed && document?.length  > 0)
    return !(completed || comment || document?.length  > 0)
    }
    isHidden = (item) => {
     /*   return ((
        (item.documents || []).length === 0
        || (item.documents.filter(doc => 
                    !(
                        (this.state.approveExcludeSwitchRef && doc.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED) ||
                        (
                            (
                                (this.state.showVIAccepted && doc.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED) ||
                                (this.state.showRejected && doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED)
                            )
                            && doc.rscPerson?.name === "Eritop VI Agent"
                        )
                    ) ||  this.filterClearance(doc)
                ).length === 0))


        || (this.state.showCompleted && !item.completed)) && !this.state.filter;
        */
       return this.applyFilter(item)
    }

    getDocuments = (checklistItem, mode = false) => {
        if ((checklistItem.documents || []).length === 0) {
            return [];
        }
        if (this.state.filter) {
            return checklistItem.documents
        }

        const Filtered = this.applyFilterOnDocument(checklistItem?.documents) 

        return  Filtered?.length > 0  ? Filtered : (this.applyCompletedFilter(checklistItem) ? checklistItem.documents : [] )
        
       /* checklistItem.documents.filter(item => 
            this.applyFilterOnDocument(item)
            && (mode? item.type === 2 : true)
            );*/
    }

    getSubActivitiesCount = (rowData) => {
        if (this.state.filter) {return rowData?.documents?.length ?? 0}
        if (rowData && rowData.documents && rowData.documents.length > 0) {
           return this.getDocuments(rowData)?.length ?? 0
        } else {
            return 0;
        }
    }

    deleteSelectedFiles = () => {
        const data = this.state.selectedDeleteDocuments;
        const rowData = this.state.selectedSubActivity;

        if (!data || data.length === 0) {
            this.setState({ openDialogDelete: false });
            toast.error("Please, select any document to delete!", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
            return;
        } else if (data.some(item => item.acceptanceStatusId !== constants.acceptanceStatusTypes.REJECTED)) {
            this.setState({ openDialogDelete: false });
            toast.error("Please, select only rejected documents to delete!", { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
            return;
        }
        const ids = data.map(item => item.id);
        let visitor = CommonHelper.getVisitorData();
        const logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logObjectId: rowData.activityId,
            logDetail: rowData.name,
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
        };
        this.setState({ isLoading: true }, () => {
            ApiClient.post("ActivityInbox/HardDeleteDocuments", { ids, deleteUser: this.props.userData.userData.userId, logInfo }).then(response => {
                if (response.data) {
                    const activity = { ...this.props.activityInboxData.currentActivity };
                    const subActivities = [...activity.subActivities];
                    const i = subActivities.findIndex(s => s.id == rowData.id);
                    const documents = subActivities[i] && [...(subActivities[i].documents && subActivities[i].documents.filter(item => !ids.includes(item.id)))];
                    subActivities[i].documents = documents;

                    activity.subActivities = subActivities;
                    this.props.setCurrentActivity(activity);

                    let allDocument = [...this.state.allDocument];
                    allDocument = allDocument.filter(item => !ids.includes(item.id));

                    this.setState({ allDocument });
                } else {
                    toast.error(constants.processNegativeMessage, { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                }
                this.setState({ isLoading: false, openDialogDelete: false });
            }).catch(error => {
                toast.error(constants.errorMessage, { containerId: 'AA', position: toast.POSITION.TOP_CENTER });
                this.setState({ isLoading: false, openDialogDelete: false })
                console.error(error);;
            });
        });
    }

    render() {
        return (<EuiRow style={{ width: "100%", minWidth: 1380 }}>

            <EuiAccordion 
            id="activityInboxAccordion"
             style={{ width: "100%" }}
              items={[
                {
                    title: this.props.activityInboxData.currentActivity.workplanName,
                    titleIcon: <i className="icon icon-document" style={{ fontSize: 17, margin: "0px 5px" }}></i>,
                    titleStyle: { paddingLeft: 0, fontWeight: 700, fontSize: "17px !important" },
                    contentStyle: { paddingLeft: 0 },
                    opened: "opened",
                    content: <Table
                        columns={this.state.subActivitiesColumns}
                        container={{ style: { background: "inherit", paddingLeft: 20 } }}
                        data={this.getSubActivities(this.props.activityInboxData.currentActivity)}
                        isLoading={this.state.isLoading}
                        options={{
                            search: false,
                            paging: false,
                            minBodyHeight: 100,
                        }}
                        tableRef={this.state.tableRef}
                        toolbar={{
                            leftCustom:
                                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-start" }}>
                                    {
                                        <Tooltip id="allExpandCollapse" title="Expand/Collapse All">
                                            <IconButton style={{ margin: 10, marginLeft: 5 }} aria-label="expandCollapseAll" id="allExpandCollapse" onClick={this.toogleAllDetailPanel}>
                                                <i className={"icon " + (this.state.expandAllDetailPanel == true ? "icon-chevron-down" : "icon-chevron-up")}></i>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <div style={{ fontSize: 18, textAlign: "left", fontWeight: 500, color: "inherit", paddingLeft: 0 }}>
                                        {this.props.activityInboxData.currentActivity.id + " - " + this.props.activityInboxData.currentActivity.name}
                                    </div>
                                </div>,
                            custom:
                                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                                    
                                    <EuiSwitch id="filter"
                                        checked={this.state.filter}
                                        onChange={(e) => this.setState({ filter: e.target.checked })}
                                        dataEnabled="Showing All"
                                        dataDisabled="Showing All"
                                        className="p-1"
                                    />
                                    <EuiSwitch checked={this.state.showCommentAvailable}
                                        name="excludeNotCompletedSubactivity"
                                        onChange={(e) => this.setState({ showCommentAvailable: e.target.checked })}
                                        dataEnabled="Comment Available"
                                        dataDisabled="Comment Available"
                                        className="p-1"
                                    />
                                    <EuiSwitch checked={this.state.showCompleted}
                                        name="excludeNotCompletedSubactivity"
                                        onChange={(e) => this.setState({ showCompleted: e.target.checked })}
                                        dataEnabled="Showing Completed"
                                        dataDisabled="Showing Completed"
                                        className="p-1"
                                    />
                                    <EuiSwitch checked={this.state.showRejected}
                                        name="excludeAcceptedSwitchActivity"
                                        onChange={(e) => this.setState({ showRejected: e.target.checked })}
                                        dataEnabled="Rejected"
                                        dataDisabled="Rejected"
                                        className="p-1"
                                    />
                                    <EuiSwitch id="showVIAccepted"
                                        checked={this.state.showVIAccepted}
                                        onChange={(e) => this.setState({ showVIAccepted: e.target.checked })}
                                        dataEnabled="VI Accepted"
                                        dataDisabled="VI Accepted"
                                        className="p-1"
                                    />
                                    <EuiSwitch id="showRejected"
                                        checked={this.state.showVIRejected}
                                        onChange={(e) => this.setState({ showVIRejected: e.target.checked })}
                                        dataEnabled="VI Rejected"
                                        dataDisabled="VI Rejected"
                                        className="p-1"
                                    />
                                    <EuiSwitch id="rscNotReviewed"
                                        checked={this.state.rscNotReviewed}
                                        onChange={(e) => this.setState({ rscNotReviewed: e.target.checked })}
                                        dataEnabled= "New  For Rating"
                                        dataDisabled= "New  For Rating"
                                        className="p-1"
                                    />


                                    {this.props.activityInboxData.currentActivity.viewOnly ? null :
                                        <div>

                                            <Tooltip id="allAccept" title="Accept All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="add" id="acceptAll" onClick={() => this.setState({ allAcceptOrReject: 2, openAllDialog: true })}>
                                                    <CheckIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip id="allReject" title="Reject All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="reject" id="rejectAll" onClick={() => this.setState({ allAcceptOrReject: 3, openAllDialog: true })}>
                                                    <CloseIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    }
                                    <Tooltip id="viewComfy" title="Tile View">
                                        <IconButton style={{ marginRight: 10, background: (this.state.viewMod === 1 ? "silver" : "inherit") }} aria-label="comfyView" id="comfyView"
                                            onClick={() => { this.setState({ viewMod: 1 }); }}>
                                            <i className="icon icon-view-tiles" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip id="viewList" title="List View">
                                        <IconButton style={{ marginRight: 10, background: (this.state.viewMod === 3 ? "silver" : "inherit") }} aria-label="listView" id="listView" onClick={() => this.setState({ viewMod: 3 })}>
                                            <i className="icon icon-view-list" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                </div>,
                        }}
                    >
                    </Table>
                },
                {
                    title: "Rating Details",
                    titleIcon: <i className={"icon icon-favorite " + ActivityInboxHelper.getColorClass(this.props.activityInboxData.currentActivity.acceptanceStatusId)}
                        style={{ fontSize: 17, margin: "0px 5px" }}></i>,
                    titleStyle: { paddingLeft: 0, fontWeight: 700, fontSize: "17px !important" },
                    contentStyle: { paddingLeft: 0 },
                    hidden: !this.props.activityInboxData.currentActivity.showRating,
                    content: <RateActivity activity={this.props.activityInboxData.currentActivity} viewOnly={true} maxHeight={true} />
                },
            ]} />

            <SummaryDocuments reworkTotal={false}
                totalStatus={(type) => this.totalStatus(type)}
                hideSessionSummary={true}
                style={{ borderBottom: "none", marginLeft: 0 }}
                title="Summary"
            ></SummaryDocuments>

            <EuiFooter id="approveActivityFooter"
                style={{ paddingBottom: 15 }}
                buttons={[
                    {
                        type: "btn warning", buttonType: "button", name: "Reject", id: "rejectActivityButton",
                        onClick: () => this.checkPendingItems(constants.activityInboxApprovalTypes.REJECT),
                        style: { marginTop: 15, },
                        display: this.props.activityInboxData.currentActivity.viewOnly,
                    },
                    {
                        type: "btn primary", name: "Approve", id: "approveSessionButton",
                        onClick: () => this.checkPendingItems(constants.activityInboxApprovalTypes.APPROVE),
                        style: { marginTop: 15, },
                        display: this.props.activityInboxData.currentActivity.viewOnly,
                    }
                ]}
                buttonsLeft={[
                    {
                        type: "btn",
                        name: "Save&Finish Later",
                        id: "saveActivityButton",
                        onClick: () => this.setState({ approvalType: constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER }, () => this.saveActivityApproval("saveActivityButton")),
                        style: { marginTop: 15, },
                        display: this.props.activityInboxData.currentActivity.viewOnly
                    },
                    {
                        type: "btn",
                        buttonType: "button",
                        name: "Cancel",
                        id: "cancelActivityButton",
                        onClick: () => { alert("Canceled") },
                        display: this.props.routing,
                        style: { marginTop: 15, },
                    },
                ]}
                infoPart={
                    (this.props.activityInboxData.currentActivity.viewOnly) ? null :
                        <ApproveSessionDialog getSessionComment={(sessionComment) => this.setState({ sessionComment })} hideRating="true" hideLabel="true" />
                }
            ></EuiFooter>

            {this.state.openDialogPreview ?
                this.renderFilePreview()
                :
                null
            }

            <DialogModal
                buttonName="Ok"
                content={<SeverityLevelDialog rejectReason={this.state.rejectReason}
                    rejectDescription={this.state.reviewComments}
                    getRejectReason={(rejectReason) => this.setState({ rejectReason })}
                    getRejectDescription={(rejectDescription) => this.setState({ reviewComments: rejectDescription })}
                />}
                handleClose={() => this.setState({ openDialog: false })}
                id="rejectActivityDialog"
                titleId="titleRejectActivityDialog"
                onClick={() => this.setState({ openDialog: false }, () => this.changeStatus())}
                open={this.state.openDialog}
                title="RSC Engineer Comments"
            >
            </DialogModal>

            {this.state.openDialogRating ?
                <DialogModal
                    buttonName="Save"
                    customStyle={{ width: 950 }}
                    content={<RateActivity activityId={this.props.activityInboxData.currentActivity.id} maxHeight={true} allDocument={this.state.allDocument} />}
                    handleClose={() => this.setState({ openDialogRating: false })}
                    id="ratingActivityDialog"
                    onClick={() => this.saveActivityApproval("ratingActivityDialog")}
                    open={this.state.openDialogRating}
                    title={this.state.approvalType === constants.activityInboxApprovalTypes.APPROVE ? "Approve Rating" : "Reject Rating"}
                    titleStyle={{ fontSize: 20 }}
                    closeButtonVisible="false"
                >
                </DialogModal>
                : null
            }

            <DialogModal
                buttonName="Confirm"
                content={<p>Are you sure you want to delete these documents permanently?</p>}
                handleClose={() => this.setState({ openDialogDelete: false })}
                id="activityDeleteDialog"
                onClick={this.deleteSelectedFiles}
                open={this.state.openDialogDelete}
                title="Delete"
                type="warning"
            >
            </DialogModal>
            <DialogModal
          buttonName="Confirm"
          buttonsVisible="false"
          content={
            <p>
              Some Mandatory items not updated, please update them to Proceed
            </p>
          }
          handleClose={() => this.setState({ openDialogMainIssue: false })}
          id="activityRateDialogMainIssue"
          onClick={() => this.setState({ openDialogMainIssue: false })}
          open={this.state.openDialogMainIssue}
          title="Issue Details"
          type="warning"
        ></DialogModal>
            <DialogModal
                content={<div style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ marginBottom: "5%" }}><EuiLoading style={{ opacity: "0.5", position: "unset" }} size="large" isLoading={true} />
                    </div>
                    <div style={{ fontWeight: "bold", marginTop: "5%" }}>Please wait few minutes.. </div>
                    <div style={{ marginTop: "2%", marginBottom: "5%" }}>Activity QA session ratings and Erisite updates are being performed!</div>
                </div>
                }
                id="activityInfoWaitDialog"
                open={this.state.openDialogInfo}
                title={<p style={{ fontWeight: "bold", fontSize: "17px" }}>Processing...</p>}
                buttonsVisible="false"
                closeButtonVisible="false"
                disableBackdropClick={true}
            >
            </DialogModal>
            <DialogModal
                buttonName="Confirm"
                content={<p>{"Are you sure you want to" + (this.state.allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? " accept " : " reject ") + "all pending documents?"}</p>}
                handleClose={() => this.setState({ openAllDialog: false })}
                id="allAcceptRejectDialog"
                onClick={() => this.changeAllStatus(this.state.allAcceptOrReject)}
                open={this.state.openAllDialog}
                title={this.state.allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "Accept All" : "Reject All"}
                type={this.state.allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "primary" : "warning"}
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'AA'} autoClose={2500} />
        </EuiRow>);
    }

}

// fix issues of validation

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityInboxData: state.activityInbox });
const mapDispatchToProps = dispatch => {
    return {
        setCurrentActivity: (activity) => dispatch({ type: "SET_CURRENT_ACTIVITY", payload: activity }),
        setActivityPageMod: (mod) => dispatch({ type: "SET_ACTIVITY_PAGE_MOD", payload: mod }),
        setRatings: (ratings) => dispatch({ type: "SET_RATINGS", payload: ratings }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApproveActivity);