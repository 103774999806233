import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import EuiHeader from "../../eui-components/header";
import constants from "../../utils/constants";
import UserContent from "./userContent";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import EuiLoading from "../../eui-components/loading";
import UserAdminHelper from "../../utils/user-admin-helper";
import CommonHelper from "../../utils/common-helper";
import { useHistory } from "react-router-dom";

const UserDetails = (props) => {

    const [isLoading, changeIsLoading] = useState(false);
    const [roleDetailMod, setRoleDetailMod] = useState(false);

    const users = useSelector(state => state.userAdmin.userList);
    const currentUser = useSelector(state => state.userAdmin.currentUser);
    const mod = useSelector(state => state.userAdmin.mod);
    const userId = useSelector(state => state.user.userData.userId);
    const dispatch = useDispatch();

    let history = useHistory();

    const getRightActions = () => {
        if (mod != constants.pageMods.VIEW_ONLY) {
            return <div style={{ paddingRight: 30 }}><button className={"btn " + (roleDetailMod ? " overlay-hidden " : "")} style={{ margin: 5 }} onClick={() => cancel()}><i className="icon icon-cross p-1"></i>Cancel</button>
                <button className={"btn primary " + (roleDetailMod ? " overlay-hidden " : "")} style={{ margin: 5 }} onClick={() => save()}><i className="icon icon-check p-1"></i>Save</button>
            </div>
        }
    }

    const checkCreateUser = () => {
        
        if (!currentUser.name ||
            !currentUser.email ||
            !currentUser.newPassword ||
            (currentUser.role ?? -1) < 0 ||
            !currentUser.signum ||
            !currentUser.confirmNewPassword) {
            toast.error("Please, fill mandatory fields.", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            return false;
        }

        const emailCheck = UserAdminHelper.isValidEmail(currentUser.email, users);
        if (emailCheck !== UserAdminHelper.validEmailMessage) {
            toast.error(emailCheck, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            return false;
        }

        if (currentUser.signum) {
            const signumCheck = UserAdminHelper.isValidSignum(currentUser.signum, users);
            if (signumCheck !== UserAdminHelper.validSignumMessage) {
                toast.error(signumCheck, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                return false;
            }
        }

        if (!CommonHelper.isValidPassword(currentUser.newPassword)) {
            toast.error(UserAdminHelper.passwordCheckInfo, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            return false;
        }

        if (currentUser.newPassword != currentUser.confirmNewPassword) {
            toast.error("Confirmation password should be the same. ", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            return false;
        }

        return true;
    }

    const checkEditUser = () => {
        if (!currentUser.name ||
            (currentUser.role ?? -1) < 0) {
            toast.error("Please, fill mandatory fields.", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            return false;
        }

        if (currentUser.signum) {
            const oldSignum = users.find(u => u.id === currentUser.id)?.signum;
            if (oldSignum != currentUser.signum) {
                const signumCheck = UserAdminHelper.isValidSignum(currentUser.signum, users);
                if (signumCheck !== UserAdminHelper.validSignumMessage) {
                    toast.error(signumCheck, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                    return false;
                }
            }
        }

        if (currentUser.newPassword) {
            if (!CommonHelper.isValidPassword(currentUser.newPassword)) {
                toast.error(UserAdminHelper.passwordCheckInfo, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                return false;
            }

            if (currentUser.newPassword != currentUser.confirmNewPassword) {
                toast.error("Confirmation password should be the same. ", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                return false;
            }
        }
        return true;
    }

    const save = () => {
        //is mail and signum unique
        if (!currentUser.id && !checkCreateUser()) {
            return;
        }

        if (currentUser.id && !checkEditUser()) {
            return;
        }

        
        const user = { ...currentUser };
        user.createdAt = user.createdAt || new Date();
        user.createUserId = user.createUserId || parseInt(userId);
        user.updatedAt = user.createdAt && new Date();
        user.updateUserId = user.createdAt && parseInt(userId);
        user.encryptedPassword = user.encryptedPassword || user.newPassword;
        user.resetPasswordToken = user.id === 0 ? null : user.newPassword;
        user.twoFactorAuthStatus = 1;
        user.roles = user.roles.filter(r => r.active);
        user.subscribedToEmailService = user.subscribedToEmailService;
        user.roles.map(item => {
            item.createDate = item.createdAt || new Date();
            item.createUserId = item.createUserId || parseInt(userId);
            item.id = 0;
        });
        
        changeIsLoading(true);
        ApiClient.post("UserAdmin/CreateEditUser", user)
            .then(response => {
                if (response.data) {
                    changeIsLoading(false);
                    if (props.isMyProfile) {
                        toast.success("Your profile information has been changed successfully.", { containerId: "MP", position: toast.POSITION.TOP_CENTER });
                        history.push("/signin");
                    } else {
                        toast.success("User has been" + (currentUser.id === 0 ? " created " : " edited ") + "successfuly.", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                        dispatch({ type: "CHANGE_USER_ADMIN_MOD", payload: constants.pageMods.LIST });
                        props.getUserList(false);
                    }
                }
            }).catch(error => {
                changeIsLoading(false);
                if (props.isMyProfile) {
                    toast.error(constants.errorMessage, { containerId: "MP", position: toast.POSITION.TOP_CENTER });
                } else {
                    toast.error(constants.errorMessage, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                }
                console.error(error);;
            });
    }

    const cancel = () => {
        if (props.isMyProfile) {
            history.push("/");
        } else {
            dispatch({ type: "CHANGE_USER_ADMIN_MOD", payload: constants.pageMods.LIST });
        }
    }

    return (
        <EuiTile style={{ padding: 5 }} tileStyle={{ padding: 0 }} fullscreen className={isLoading ? " disabled " : ""}>
            <EuiLoading id="userDetailLoading" isLoading={isLoading} />
            <EuiHeader id="userDetailsHeaderId" title={<EuiBreadcrumb id="userBreadcrumb"
                parentName={<div className="flex-start-content"><i className="icon icon-home icon-lg pr-1"></i>Home</div>}
                childName={currentUser.name || "New User"}
                onClickParent={() => { props.isMyProfile ? history.push("/") : dispatch({ type: "CHANGE_USER_ADMIN_MOD", payload: constants.pageMods.LIST }); }}
                className="p-2"
                style={{ display: "flex", alignItems: "center" }}
            />}
                rightActions={getRightActions()}></EuiHeader>
            <UserContent roleDetailMod={roleDetailMod} setRoleDetailMod={setRoleDetailMod} isMyProfile={props.isMyProfile}></UserContent>
        </EuiTile>
    );

}

export default UserDetails;