import React from "react";
import CommonHelper from "./common-helper";
import jwt_decode from 'jwt-decode';
import constants from "./constants";
import AutoAssignmentHelper from "./auto-assignment-helper";
import { getAuthToken } from "./adalConfig";


export default class UserAdminHelper {

    static loginRoles = JSON.parse(localStorage.getItem("userLoginRoles"));

    static rscCenters = JSON.parse(localStorage.getItem("rscCenters"));

    static validEmailMessage = "Valid email adress";

    static validSignumMessage = "Valid Signum";

    static passwordCheckInfo = "Password should be at least 8 characters, one symbol(!@#$%^&*._), upper and lower case, number.";

    static mapUsers = (users) => {
        if ((users || []).length === 0) {
            return [];
        }
        users.map(item => {
            item.editId = item.id;
            item.roles = [item.role, ...(item.roles || []).filter(r => r != item.role)];
        });
        
        return users;
    }

    static mapUser = (user) => {
        user.roles = this.loginRoles.map(item => {
            return {
                ...item,
                loginRoleId: item.id,
                role: item.id,
                name: item?.loginName,
                active: user.roles?.map(r => r.loginRoleId)?.includes(item.id) || item.id === user.role,
            };
        });
        user.customers = user.customers || [];
        user.customers.map(c => {
            c.text = c.customer?.name;
            c.countryId = c.customer?.countryId;
        });
        user.userAspCompanies = user.userAspCompanies || [];
        user.userAspCompanies.map(a => {
            a.text = a.aspCompany?.name;
            a.countryId = a.aspCompany?.countryId;
        });
        user.rscPersonUsers = user.rscPersonUsers || [];
        user.rscPersonUsers.map(u => {
            u.value = u.rscPerson?.rscCenterId;
            u.text = this.rscCenters.find(r => r.value == u.rscPerson?.rscCenterId)?.text;
        });

        if ((user.role + "") === constants.userRoles.CUSTOMER && user.customerUsers?.length > 0) {
            user.signature = user.customerUsers[0].custSignaturePath;
        }

        
    }

    static getUserStatus = (status) => {
        if (status === true) {
            return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-green ml-1 mr-1"></i>Active</div>`;
        } else {
            return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-red ml-1 mr-1"></i>Not Active</div>`;
        }
    }

    static isValidSignum = (signum, users) => {
        
        if ((signum || "").length === 7) {
            const re = /^[A-Za-z]+$/;
            if (!re.test(signum)) {
                return "Signum only can contain letters and max 7, no space.";
            }

            const allSignum = users.map(u => (u.signum || "").toLowerCase());
            if (allSignum.includes(signum.toLowerCase())) {
                return "Signum already in use, please type another signum"
            } else {
                return this.validSignumMessage;
            }
        }
        return "Signum only can contain letters and min 7, no space.";
    }
    static isValidResourceSignum = (signum) => {
        if ((signum || "").length === 7) {
            const re = /^[A-Za-z]+$/;
            if (!re.test(signum)) {
                return "Signum only can contain letters and max 7, no space.";
            }
            else {
                return this.validSignumMessage;
            }
        }
        return "Signum only can contain letters and min 7, no space.";
    }
    static isValidEmail = (email, users) => {
        if (!CommonHelper.isValidEmail(email)) {
            return "Invalid email adress";
        }
        const allEmails = users.map(u => (u.email || "").toLowerCase());

        if (allEmails.includes(email.toLowerCase())) {
            return "Email already in use, please type another email.";
        }
        return this.validEmailMessage;
    }

    static mapLoginUser = (loginUser, loginRoles, allRscCenters , activeJobRole = null) => {
        
        
        var tokenData = jwt_decode(getAuthToken());
        var user = loginUser.user;
        user.loginComment = loginUser?.message ?? ""
        const rscPersons = user.rscPersonUsers?.map(r => r.rscPerson);

        AutoAssignmentHelper.mapRscPersons(rscPersons);

        const jobRole = activeJobRole ? activeJobRole : user?.role?.toString() 
        const userData = {...tokenData , id : user?.id?.toString(),
        "username": tokenData?.name,
        "userId": user?.id?.toString(),
        "jobRole": jobRole
    }
        debugger;
        if (!user.roles?.find(r => r.loginRoleId === user.role)) {
            user.roles = [{ loginRoleId: user.role, userId: user.id }];
        }
        const userLoginRoles = user.roles?.map(l => {
            return {
                value: l.loginRoleId,
                text: loginRoles.find(r => r.id === l.loginRoleId)?.loginName,
                rscCenters: rscPersons.filter(p => p.personType === l.loginRoleId).map(c => {
                    return {
                        ...c,
                        value: c.rscCenterId,
                        text: allRscCenters.find(a => a.id === c.rscCenterId)?.name,
                        role: c.personType
                    }
                }),

            }
        });
        const mappedLoginUser = {
            userDetail: user,
            userData,
            token: loginUser.token,
            loginRole: loginUser.loginRole,
            loggedIn: true,
            loginRoles: userLoginRoles,
            userRscCenters: userLoginRoles.find(u => u.value === user.role)?.rscCenters,
            rscPerson: rscPersons.find(r => r.personType === user.role),
        }

        return mappedLoginUser;
    }

    static getUserRoleName = (role) => {
        if ((role ?? -1) === -1) {
            return "";
        }
        return this.loginRoles?.find(item => item.id === role)?.loginName;
    }

    static getUserRscCenterName = (rscCenterId) => {
        if (!rscCenterId) {
            return "";
        }

        return this.rscCenters?.find(item => item.value === rscCenterId)?.text;
    }

    static getIcon = (role) => {
        if (constants.customerRoles.includes(role)) {
            return <i className="icon icon-scan text-lg p-1"></i>;
        } else if (constants.ericssonEmployeeRoles.includes(role)) {
            return <i className="icon icon-econ text-lg p-1"></i>;
        } else if (constants.aspRoles.includes(role)) {
            return <i className="icon icon-avatar text-lg p-1"></i>;
        } else if (constants.readOnlyRoles.includes(role)) {
            return <i className="icon icon-eye text-lg p-1"></i>;
        }
        return null;
    }

}